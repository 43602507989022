import constants from '@/constants'
import Home from '@/views/Home.vue'
import { RouteConfig } from 'vue-router'
import store from '@/store'
import { ACTION_NEW_COVERAGE } from '@/store/modules/product/actionTypes'

export const publicRoutes: Array<RouteConfig> = [
  {
    path: '/',
    name: constants.routeNames.HOME,
    component: Home,
    meta: {
      pageIndex: 0
    }
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    name: constants.routeNames.NOT_FOUND,
    component: () =>
      import(/* webpackChunkName: "NotFound" */ '../views/Error/NotFound.vue')
  },
  {
    path: '/error',
    name: constants.routeNames.ERROR,
    component: () =>
      import(/* webpackChunkName: "Error" */ '../views/Error/Error.vue')
  },
  {
    path: '/deniedCamera',
    name: constants.routeNames.DENIED_CAMERA,
    component: () =>
    import(/* webpackChunkName: "DeniedCamera" */ '../views/Error/DeniedCamera.vue')
  },
  {
    path: '/expire',
    name: constants.routeNames.EXPIRE,
    component: () =>
      import(/* webpackChunkName: "Error" */ '../views/Expire.vue')
  },
  {
    path: '/invalid/device',
    name: constants.routeNames.MOBILE_ONLY,
    component: () =>
      import(/* webpackChunkName: "NotMobileDevice" */ '../views/Error/NotMobileDevice.vue')
  },
  {
    path: '/terms',
    name: constants.routeNames.TERMS_OF_USE,
    component: () =>
      import(/* webpackChunkName: "TermsOfUse" */ '../views/TermsOfUse.vue')
  },
  {
    path: '/site',
    name: constants.routeNames.SITE_POLICY,
    component: () =>
      import(/* webpackChunkName: "SitePolicy" */ '../views/SitePolicy.vue')
  },
  {
    path: '/account',
    name: constants.routeNames.ACCOUNT_POLICY,
    component: () =>
      import(/* webpackChunkName: "AccountPolicy" */ '../views/AccountPolicy.vue')
  },
  {
    path: '/notice',
    name: constants.routeNames.NOTICES,
    component: () =>
      import(/* webpackChunkName: "Notices" */ '../views/Notices.vue')
  },
  {
    path: '/privacy',
    name: constants.routeNames.PRIVACY_POLICY,
    component: () =>
      import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/contact',
    name: constants.routeNames.CONTACT_US,
    component: () =>
      import(/* webpackChunkName: "ContactUs" */ '../views/ContactUs.vue')
  },
  {
    path: '/contact/email',
    name: constants.routeNames.CONTACT_US_EMAIL,
    component: () =>
      import(/* webpackChunkName: "ContactUs" */ '../views/ContactUsEmail.vue')
  },
  {
    path: '/contact/email/success',
    name: constants.routeNames.CONTACT_US_EMAIL_SUCCESS,
    component: () =>
      import(/* webpackChunkName: "ContactUs" */ '../views/ContactUsEmailSuccess.vue')
  },
  {
    path: '/signin',
    name: constants.routeNames.SIGN_IN,
    component: () =>
      import(/* webpackChunkName: "Signin" */ '../views/SignIn.vue'),
    meta: {
      pageIndex: 1
    }
  },
  {
    path: '/application/terms',
    name: constants.routeNames.BEFORE_APPLICATION,
    beforeEnter(to, from, next) {
      store.dispatch(ACTION_NEW_COVERAGE)
        .then(() => {
          next()
        })
        .catch(e => {
          next('/error?error=' + encodeURIComponent(e.message))
        })
    },
    component: () =>
      import(/* webpackChunkName: "ApplicationTerm" */ '../views/Terms.vue'),
    meta: {
      pageIndex: 2
    }
  },
  {
    path: '/otp',
    name: 'OTP',
    component: () =>
      import(/* webpackChunkName: "OTP" */ '../views/OtpAuth.vue')
  }
]
