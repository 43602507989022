import { PolicyPartyModel } from 'shift-policy-service-api-client'
import { UserState } from '@/store/api/UserState'
import { MutationTree } from 'vuex'
import { SET_EMAIL, SET_NEW_EMAIL, SET_POLICY_HOLDER, SET_USER_STATE } from './mutationTypes'

export const mutations: MutationTree<UserState> = {
  [SET_EMAIL](state: UserState, payload: string) {
    state.email = payload
  },
  [SET_NEW_EMAIL](state: UserState, payload: string) {
    state.newEmail = payload
  },
  [SET_USER_STATE](state: UserState, payload: UserState) {
    for (const key of Object.keys(payload)) {
      state[key] = payload[key]
    }
  },
  [SET_POLICY_HOLDER](state: UserState, payload: PolicyPartyModel) {
    state.policyHolder = payload
  }
}
