


























































































import { Component, Vue } from 'vue-property-decorator'
import constants from '@/constants'
import { modalBeforeCloseHandling, modalBeforeOpenHandling } from '@/services/functions'
import { EventBus as aonEventBus } from '@/plugins/eventBus'
import { Getter } from 'vuex-class'
import Accordion from '@/components/Accordion.vue'

@Component({
  components: {
    Accordion
  }
})
export default class ServiceNoticesModal extends Vue {
  name = constants.modalNames.SERVICE_NOTICES_MODAL

  @Getter('app/showTermination')
  showTermination!: boolean

  @Getter('app/servicNotice2')
  servicNotice2!: boolean

  goToContactUs() {
    if (this.$router.currentRoute.name !== constants.routeNames.CONTACT_US_EMAIL) {
      this.$router.push({ name: constants.routeNames.CONTACT_US_EMAIL })
    }
    this.onCancel()
    aonEventBus.$emit(constants.eventNames.TOGGLE_MENU)
  }

  onCancel () {
    this.$modal.hide(this.name)
  }

  async onConfirm () {
    this.$modal.hide(this.name)
  }

  private restorePoint = 0

  beforeOpen() {
    this.restorePoint = document?.documentElement?.scrollTop || 0
    modalBeforeOpenHandling()
  }

  beforeClose() {
    modalBeforeCloseHandling(this.restorePoint)
  }
}
