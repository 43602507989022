import axios from 'axios'
import { Config } from '@/config'

const publicPath = Config.publicPath ? `${Config.publicPath}` : '/'

let apiKey = process.env.VUE_APP_POSTCODEJP_API_KEY || ''

// Fetches api key from json file
export const PostcodeApiKeyService = {
  async getApiKey(): Promise<string> {
    if (apiKey) {
      return apiKey
    } else {
      const resp = await axios.get<any>(`${publicPath}postcode-apikey.json`)
      const hostName = window.location.hostname
      apiKey = resp.data[hostName] || ''
      return apiKey
    }
  }
}
