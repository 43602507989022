import modalName from '../modalName'

export function warrantyPhotoModalDef($t: any) {
  return {
    name: modalName.INFO_WARRANTY_MODAL,
    lines: [
      {
        type: 'h1',
        text: `<span class="text">${$t('warrantyPhotoModal.title')}</span>`
      },
      {
        type: 'h2',
        text: $t('warrantyPhotoModal.steps.1.heading')
      },
      {
        type: 'p',
        text: $t('warrantyPhotoModal.steps.1.text')
      },
      {
        type: 'img',
        imageSrc: require('@/assets/switch_back@2x.jpg')
      },
      {
        type: 'p',
        class: 'note',
        text: `<span class=asterix>*</span><span>${$t('warrantyPhotoModal.steps.1.note')}</span>`
      },
      {
        type: 'aside',
        text: `<img src="${require('@/assets/icon-diamond-warning.svg')}"/><span><h1>${$t('warrantyPhotoModal.steps.2.caution.heading')}</h1></span>`
      },
      {
        type: 'ul',
        class: 'bullet',
        text: `<li>${$t('warrantyPhotoModal.steps.2.caution.1')}</li>`
      },
      {
        type: 'ul',
        class: 'bullet',
        text: `<li>${$t('warrantyPhotoModal.steps.2.caution.2')}</li>`
      }
    ]
  }
}
