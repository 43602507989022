export const SET_CLAIM_OVERLAYS = 'SET_CLAIM_OVERLAYS'
export const SET_NEW_SERIALIZED = 'SET_NEW_SERIALIZED'
export const SET_CLAIM_STATE = 'SET_CLAIM_STATE'
export const SET_REPAIR_POLICY_MODEL = 'SET_REPAIR_POLICY_MODEL'
export const SET_CLAIM_FACT_VALUE = 'SET_CLAIM_FACT_VALUE'
export const SET_CLAIM_VALIDATE = 'SET_CLAIM_VALIDATE'
export const SET_SUBMITTED_CLAIM = 'SET_SUBMITTED_CLAIM'
export const SET_REPAIRS = 'SET_REPAIRS'
export const SET_FACTS = 'SET_FACTS'
export const SET_CONSENT = 'SET_CONSENT'
