import { ProductState } from '@/store/api/ProductState'

export const initialState: ProductState = {
  serializedConfig: null,
  productModel: null,
  sales: null,
  validationErrors: [],
  loaded: false,
  invoiceId: null,
  convertQuote: null,
  bundle: null,
  saleJourneyState: {
    isProposalSubmited: false,
    isQuotedCreated: false,
    isPolicyCreated: false,
    isInvoiceIdAvailable: false,
    isPaymentStarted: false,
    isGmoOk: false,
    isPaymentCompleted: false
  }
}
