import { UserState } from '@/store/api/UserState'
import { RootState } from '@/store/api/RootState'
import { GetterTree } from 'vuex'

export const getters: GetterTree<UserState, RootState> = {
  email(state: UserState): string {
    return state.email
  },
  newEmail(state: UserState): string {
    return state.newEmail
  },
  name(state: UserState): string {
    if (state.policyHolder) {
      const { name, email } = state.policyHolder
      return name || email
    }
    return state.email
  },
  familyName(state: UserState): string {
    if (state.policyHolder) {
      return state.policyHolder.familyName
    }

    return ''
  },
  givenName(state: UserState): string {
    if (state.policyHolder) {
      return state.policyHolder.givenName
    }

    return ''
  }
}
