import constants from '@/constants'
import { NavigationGuardNext, RouteConfig } from 'vue-router'
import store from '@/store'
import { isDeactivateUpdatePayment } from '@/services/functions'

// as a navigation guard to prevent access to coverage journey when cart was empty
const checkEmptyCart = (next: NavigationGuardNext<Vue>) => {
  if (store.state.product.sales?.cart.isEmpty || store.state.product.serializedConfig === null) {
    next({ name: constants.routeNames.DASHBOARD })
  } else {
    next()
  }
}

export const coverageRoutes: Array<RouteConfig> = [
  {
    path: '/application/email',
    name: constants.routeNames.APPLICATION_EMAIL,
    component: () => import(/* webpackChunkName: "Email Auth" */ '@/views/Application/EmailAuth.vue'),
    meta: {
      pageIndex: 2.1
    }
  },
  {
    path: '/application/otp',
    name: constants.routeNames.APPLICATION_OTP,
    component: () => import(/* webpackChunkName: "Otp Auth" */ '@/views/Application/OtpAuth.vue'),
    meta: {
      pageIndex: 2.2
    }
  },
  {
    // select device type
    path: '/auth/application/device',
    name: constants.routeNames.APPLICATION_DEVICE,
    component: () => import(/* webpackChunkName: "Device" */ '@/views/Application/Device.vue'),
    meta: {
      pageIndex: 2.3
    }
  },
  {
    // select plan type
    path: '/auth/application/plan',
    name: constants.routeNames.APPLICATION_PLAN,
    beforeEnter: (to, from, next) => checkEmptyCart(next),
    component: () => import(/* webpackChunkName: "Plan" */ '@/views/Application/Plan.vue'),
    meta: {
      pageIndex: 2.4
    }
  },
  {
    path: '/auth/application',
    name: constants.routeNames.APPLICATION,
    component: () => import(/* webpackChunkName: "Application" */ '@/views/Application/Application.vue'),
    meta: {
      pageIndex: 2.5
    },
    props: {
      steps: 4
    },
    children: [
      {
        // warranty card/receipt upload
        path: 'auth/cert',
        name: constants.routeNames.APPLICATION_CERT,
        beforeEnter: (to, from, next) => checkEmptyCart(next),
        components: {
          content: () => import(/* webpackChunkName: "Application - Proof Cert" */ '@/views/Application/components/ProofCert.vue')
        },
        meta: {
          step: 1
        }
      },
      {
        // application form and device photo upload
        path: 'auth/form',
        name: constants.routeNames.APPLICATION_FORM,
        beforeEnter: (to, from, next) => checkEmptyCart(next),
        components: {
          content: () => import(/* webpackChunkName: "Application - Form" */ '@/views/Application/components/AppForm.vue')
        },
        meta: {
          step: 2
        }
      },
      {
        path: 'auth/summary',
        name: constants.routeNames.APPLICATION_SUMMARY,
        beforeEnter: (to, from, next) => checkEmptyCart(next),
        components: {
          content: () => import(/* webpackChunkName: "Application - Summary" */ '@/views/Application/components/Summary.vue')
        },
        meta: {
          step: 3
        }
      },
      {
        path: 'auth/payment',
        name: constants.routeNames.APPLICATION_PAYMENT,
        beforeEnter: (to, from, next) => {
          if (isDeactivateUpdatePayment()) {
            next({ name: constants.routeNames.ERROR })
          } else next()
        },
        components: {
          content: () => import(/* webpackChunkName: "Application - Payment" */ '@/views/Application/components/PaymentDetails.vue')
        },
        meta: {
          step: 4
        }
      }
    ]
  },
  {
    path: '/application/auth/completed',
    name: constants.routeNames.APPLICATION_COMPLETED,
    component: () => import(/* webpackChunkName: "Application - Completed" */ '@/views/Application/Completed.vue'),
    meta: {
      pageIndex: 2.5
    }
  }
]
