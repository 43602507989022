export default {
  DATE: 'YYYY-MM-DD',
  NATIVE_DATE: 'yyyy-MM-DD',
  CC_EXPIRY_DATE: 'MM-yyyy',
  NATIVE_CC_EXPIRY_DATE: 'yyyy-MM',
  DATE_MIN_EPOCH: -8640000000000000,
  TYPE: {
    CC: 'CC',
    DATE: 'DATE'
  }
}
