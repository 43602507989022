









import constants from '@/constants'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Footer extends Vue {
  goBack() {
    this.$router.back()
  }

  onLinkClick() {
    this.$modal.show(constants.modalNames.COMMERCIAL_TRANSACTIONS_MODAL)
  }
}
