





















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import constants from '@/constants'
import { EventBus } from '@/plugins/eventBus'
import { Auth } from '@/services/Auth'
import { EventBus as ShiftBus, AUTH_EVENT_NAMES, AuthEvent } from '@uncharted/coverhub-framework'
import store from '@/store'
import { ACTION_LOGOUT } from '@/store/modules/user/actionTypes'
import { Getter } from 'vuex-class'
import { InfoModalDescription } from '@/interfaces'
import { getModalContent } from '@/constants/modalContents'
import InfoModal from '@/components/modals/InfoModal.vue'

@Component({
  components: {
    InfoModal
  }
})
export default class Header extends Vue {
  @Getter('user/name') userName!: string
  @Getter('app/newTermUse') newTermUse!: string

  isMenuActive = false
  isShow = true
  lastScrollPosition = 0
  loggedIn = false
  transitionName = ''

  get currentLocale() {
    return this.$i18n.locale
  }

  created() {
    this.onAuthEvent = this.onAuthEvent.bind(this)
    ShiftBus.on(AUTH_EVENT_NAMES.AUTH_EVENT, this.onAuthEvent)
    this.loggedIn = Auth.loggedInAs('otp')
  }

  destroyed() {
    ShiftBus.off(AUTH_EVENT_NAMES.AUTH_EVENT, this.onAuthEvent)
  }

  onAuthEvent(event: AuthEvent) {
    this.loggedIn = event.name === 'login'
  }

  goToHome() {
    if (this.loggedIn) {
      if (this.$router.currentRoute.name !== constants.routeNames.DASHBOARD) {
        this.$router.replace({ name: constants.routeNames.DASHBOARD })
      }
    } else {
      if (this.$router.currentRoute.name !== constants.routeNames.HOME) {
        this.$router.replace({ name: constants.routeNames.HOME })
      }
    }
    this.closeMenu()
  }

  toggleMenu() {
    this.isMenuActive = !this.isMenuActive
    this.transitionName = this.isMenuActive ? constants.transitionNames.RIGHT_SLIDE_IN : constants.transitionNames.RIGHT_SLIDE_OUT
    EventBus.$emit(this.isMenuActive ? constants.eventNames.OPEN_MENU : constants.eventNames.CLOSE_MENU)
  }

  closeMenu() {
    this.isMenuActive = false
    this.transitionName = constants.transitionNames.RIGHT_SLIDE_OUT
    EventBus.$emit(constants.eventNames.CLOSE_MENU)
  }

  logout() {
    store.dispatch(ACTION_LOGOUT).then(() => {
      if (this.$router.currentRoute.name !== constants.routeNames.HOME) {
        this.$router.replace({ name: constants.routeNames.HOME })
      }
      this.closeMenu()
    })
  }

  signIn() {
    if (this.$router.currentRoute.name !== constants.routeNames.SIGN_IN) {
      this.$router.replace({ name: constants.routeNames.SIGN_IN })
    }
    this.closeMenu()
  }

  goToNotices() {
    this.$modal.show(constants.modalNames.NOTICES_MODAL)
  }

  goToServiceNotices() {
    this.$modal.show(constants.modalNames.SERVICE_NOTICES_MODAL)
  }

  goToTermsOfUse() {
    this.$modal.show(this.newTermUse ? constants.modalNames.NEW_TERM_OF_USE_MODAL : constants.modalNames.TERM_OF_USE_MODAL)
  }

  goToAccountPolicy() {
    this.$modal.show(constants.modalNames.ACCOUNT_POLICY_MODAL)
  }

  goToPrivacyPolicy() {
    this.$modal.show(constants.modalNames.PRIVACY_POLICY_MODAL)
  }

  goToSwitchWideCare() {
    this.$modal.show(constants.modalNames.SERVICE_INTRODUCTION_MODAL)
  }

  goToFaq() {
    this.$modal.show(constants.modalNames.FAQ_MODAL)
  }

  goToDashboard() {
    this.$router.push({ name: constants.routeNames.DASHBOARD })
    this.toggleMenu()
  }

  goToContactUs() {
    if (this.$router.currentRoute.name !== constants.routeNames.CONTACT_US) {
      this.$router.push({ name: constants.routeNames.CONTACT_US })
    }
    this.closeMenu()
  }

  howToCheckSerial() {
    if (this.description) {
      this.$modal.show(this.description.name)
    }
  }

  get description(): InfoModalDescription | null {
    return getModalContent('photofront', this.$t.bind(this), '_HEADER')
  }

  onScroll() {
    const pageYOffset = window.pageYOffset || document.documentElement.scrollTop
    const headerOffset = 80

    // alway show header if less than headerOffset
    if (pageYOffset <= headerOffset) {
      this.showHeader()
      return
    }

    const currentScrollPosition = pageYOffset - headerOffset
    if (currentScrollPosition < 0) {
      return
    }

    this.isShow = currentScrollPosition <= this.lastScrollPosition
    this.lastScrollPosition = currentScrollPosition
  }

  showHeader() {
    this.isShow = true
  }

  mounted() {
    window.addEventListener('scroll', this.onScroll)
    EventBus.$on(constants.eventNames.SHOW_HEADER, this.showHeader)

    EventBus.$on(constants.eventNames.TOGGLE_MENU, () =>
      this.toggleMenu()
    )
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
    EventBus.$off(constants.eventNames.SHOW_HEADER)
  }

  @Watch('isMenuActive')
  onOpen(isActive: boolean) {
    if (isActive) {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
  }
}
