import { ClaimState } from '@/store/api/ClaimState'
import { SubmittedClaim } from '@/store/api/models/SubmittedClaim'

export const initialState: ClaimState = {
  serializedConfig: null,
  overlays: [],
  repairPolicyModel: null,
  validationErrors: [],
  violations: [],
  submittedClaim: {
    createdDate: '',
    claimNumber: ''
  } as SubmittedClaim,
  repairs: [],
  facts: [],
  consent: 'false'
}
