import { Config } from '@/config'
import { EventBus } from '@/plugins/eventBus'
import Vue from 'vue'
import VueRouter, { NavigationGuardNext, RawLocation, RouteConfig } from 'vue-router'
import constants from '@/constants'
import { Auth } from '@/services/Auth'
import { AuthResult } from '@uncharted/coverhub-framework'
import protectedRoutes from '@/router/auth'
import { publicRoutes } from '@/router/public-routes'
import store from '@/store'
import { ACTION_LOAD_PRODUCT } from '@/store/modules/product/actionTypes'
import routeNames from '@/constants/routeNames'
import { isMobileDevice, isProduction } from '@/services/functions'
import { ErrorHandler, Route } from 'vue-router/types/router'
import { ACTION_SET_AFFILIATE_ID } from '@/store/modules/app/actionTypes'

const handleAffID = (to: Route) => {
  const affID = to?.query?.aff_id || ''
  const affIDPattern = /^[a-zA-Z0-9]{1,10}$/
  if (affID) {
    store.dispatch(ACTION_SET_AFFILIATE_ID, affIDPattern.test(affID as string) ? affID : '') // set valid affiliate ID only
  }
}

const methodToOverwrite = ['push', 'replace']
methodToOverwrite.forEach((method: string) => {
  const originalMethod = (VueRouter as any).prototype[method];
  (VueRouter as any).prototype[method] = function(location: RawLocation) {
    return originalMethod.call(this, location).catch((reason: ErrorHandler) => {
      // 2 = redirected, 4 = aborted, 8 = cancelled, 16 = Duplicate
      // refer to NavigationFailureType in vue-router
      const isNavigationRedundant = /Avoided redundant navigation to current location:/i.test(reason.toString()) || false
      const isNavigationAborted = VueRouter.isNavigationFailure(reason, 4)
      const isNavigationCancelled = VueRouter.isNavigationFailure(reason, 8)
      const isNavigationDuplicate = VueRouter.isNavigationFailure(reason, 16)

      if (!(
        isNavigationRedundant ||
        isNavigationAborted ||
        isNavigationCancelled ||
        isNavigationDuplicate
      )) {
        throw reason
      }
    })
  }
})

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [...protectedRoutes, ...publicRoutes]

const router = new VueRouter({
  mode: 'history',
  // eslint-disable-next-line
  base: __webpack_public_path__,
  routes
})

function loadProduct(next: NavigationGuardNext<Vue>) {
  store.dispatch(ACTION_LOAD_PRODUCT).then(next)
    .catch(e => {
      next('/error?error=' + encodeURIComponent(e.message))
    })
}

router.beforeEach(async (to, from, next) => {
  // close image viewer when route change
  EventBus.$emit(constants.eventNames.CLOSE_IMAGE_VIEWER)

  // refresh if newer version released
  if (store.state.app.version !== store.state.app.newVersion) {
    window.location.href = Config.appUrl + to.fullPath
  }

  if (isProduction() && !await isMobileDevice() && to.name === routeNames.BEFORE_APPLICATION) {
    await next({ name: routeNames.MOBILE_ONLY })
  } else if (to.name !== routeNames.ERROR && to.name !== routeNames.HOME) {
    if (
      to.path.startsWith('/application/auth') ||
      to.path.startsWith('/auth')
    ) {
      Auth.protect('otp').then((res: AuthResult) => {
        if (res.success) {
          loadProduct(next)
        } else {
          next('/expire')
        }
      })
    } else {
      Auth.protect('guest').then((res: AuthResult) => {
        if (res.success) {
          next()
        } else {
          next('/error')
        }
      })
    }
  } else {
    next()
  }
})

router.afterEach((to) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  EventBus.$emit(constants.eventNames.SHOW_HEADER)
  handleAffID(to)
})

router.onError(error => {
  if (/ChunkLoadError/i.test(error.name)) {
    window.location.reload()
  }
})

export default router
