


































import { Component, Prop, Vue } from 'vue-property-decorator'
import { InfoModalDescription } from '@/interfaces'
import { modalBeforeCloseHandling, modalBeforeOpenHandling } from '@/services/functions'

@Component
export default class InfoModal extends Vue {
  @Prop() private description!: InfoModalDescription
  onCancel() {
    this.$modal.hide(this.description.name)
  }

  async onConfirm() {
    this.$modal.hide(this.description.name)
  }

  private restorePoint = 0

  beforeOpen() {
    this.restorePoint = document?.documentElement?.scrollTop || 0
    modalBeforeOpenHandling()
  }

  beforeClose() {
    modalBeforeCloseHandling(this.restorePoint)
  }
}
