export const SET_APP_VERSION = 'SET_APP_VERSION'
export const SET_APP_NEW_VERSION = 'SET_APP_NEW_VERSION'
export const SET_APP_AFFILIATE_ID = 'SET_APP_AFFILIATE_ID'
export const SET_APP_SHOW_TERMINATION = 'SET_APP_SHOW_TERMINATION'
export const SET_APP_SHOW_TERMINATION2 = 'SET_APP_SHOW_TERMINATION2'
export const SET_APP_SHOW_TERM_AND_CONDITION = 'SET_APP_SHOW_TERM_AND_CONDITION'
export const SET_APP_DISABLE_PLAN_CHANGE = 'SET_APP_DISABLE_PLAN_CHANGE'
export const SET_APP_SUSPEND_REGISTRATION = 'SET_APP_SUSPEND_REGISTRATION'
export const SET_APP_DEACTIVATE_NEW_DEVICE = 'SET_APP_DEACTIVATE_NEW_DEVICE'
export const SET_APP_DEACTIVATE_UPDATE_PAYMENT = 'SET_APP_DEACTIVATE_UPDATE_PAYMENT'
export const SET_APP_DEACTIVATE_CANCEL_COVERAGE = 'SET_APP_DEACTIVATE_CANCEL_COVERAGE'
export const SET_APP_EXTRA_WAITING_MSG = 'SET_APP_EXTRA_WAITING_MSG'
export const SET_APP_SERVICE_NOTICE_2 = 'SET_APP_SERVICE_NOTICE_2'
export const SET_APP_NEW_TERM_USE = 'SET_APP_NEW_TERM_USE'
