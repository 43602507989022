import { claimsRoutes } from './claims-routes'
import { coverageRoutes } from './coverage-routes'
import { dashboardRoutes } from './dashboard-routes'
import { policyRoutes } from './policy-routes'
import { userProfileRoutes } from './user-profile-routes'

export default [
  ...claimsRoutes,
  ...coverageRoutes,
  ...dashboardRoutes,
  ...policyRoutes,
  ...userProfileRoutes
]
