import { SwitchPolicyModel } from '@/services/api/models/SwitchPolicyModel'
import { CancelPlanModel } from '@/store/api/models/CancelPlanModel'
import { PhotoModel } from '@/store/api/models/PhotoModel'
import { PolicyCertificateModel } from '@/store/api/models/PolicyCertificateModel'
import { PolicyState } from '@/store/api/PolicyState'
import { RootState } from '@/store/api/RootState'
import { PolicyModel } from 'shift-policy-service-api-client'
import { GetterTree } from 'vuex'

export interface FnGetPolicyById {
  (id: string): SwitchPolicyModel | undefined;
}

export const getters: GetterTree<PolicyState, RootState> = {
  policies(state: PolicyState): SwitchPolicyModel[] {
    return state.policies
  },
  getById: (state: PolicyState) => (id: string): SwitchPolicyModel | undefined => {
    return state.policies.find(p => p.policyId === id)
  },
  cancelPlan(state: PolicyState): CancelPlanModel | null {
    return state.cancelPlan
  },
  photos(state: PolicyState): PhotoModel | null {
    return state.photos
  },
  policyCertificate(state: PolicyState): PolicyCertificateModel | null {
    return state.policyCertificate
  },
  convertedPolicy(state: PolicyState): PolicyModel | null {
    return state.convertedPolicy
  }
}
