




















import { Component, Vue } from 'vue-property-decorator'
import constants from '@/constants'
import ExternalLinkModal from './ExternalLinkModal.vue'
import { modalBeforeCloseHandling, modalBeforeOpenHandling } from '@/services/functions'

@Component({
  components: {
    ExternalLinkModal
  }
})
export default class CommercialTransactionsModal extends Vue {
  name = constants.modalNames.COMMERCIAL_TRANSACTIONS_MODAL

  onCancel () {
    this.$modal.hide(this.name)
  }

  private restorePoint = 0

  beforeOpen() {
    this.restorePoint = document?.documentElement?.scrollTop || 0
    modalBeforeOpenHandling()
  }

  beforeClose() {
    modalBeforeCloseHandling(this.restorePoint)
  }
}
