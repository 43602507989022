import './public-path'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import '@/plugins/vue-js-modal'
import '@/plugins/vue-shortkey'
import '@/plugins/pretty-checkbox-vue'
import '@/plugins/globalData'
import '@/plugins/v-idle'
import { progress } from '@uncharted/coverhub-framework'
// Global components
import ButtonField from '@/components/layout/ButtonField.vue'
import NextButton from '@/components/layout/NextButton.vue'
import ApplyButton from '@/components/layout/ApplyButton.vue'
import BackButton from '@/components/layout/BackButton.vue'
import Container from '@/components/layout/Container.vue'
import Panel from '@/components/layout/Panel.vue'
import Field from '@/components/layout/Field.vue'
import { sentryErrorHandler } from './sentry'
import { Config } from '@/config'

progress(500)

Vue.config.productionTip = false

// Registering global components
Vue.component('button-field', ButtonField)
Vue.component('next-button', NextButton)
Vue.component('apply-button', ApplyButton)
Vue.component('back-button', BackButton)
Vue.component('container', Container)
Vue.component('panel', Panel)
Vue.component('field', Field)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

Vue.config.errorHandler = err => {
  if (Config.environment === 'production' && !window.location.host.match(/.*local.*/)) {
    sentryErrorHandler(err)
  } else {
    console.error('UnhandledError', err)
  }
}
