














import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component
export default class AonButton extends Vue {
  @Prop() route?: string;
  @Prop() to?: any
  @Prop() disabled?: boolean
  @Prop({ default: 'button' }) dataTc!: string;
  @Prop({ default: 'secondary' }) type!: string;

  protected onClick (evt: Event) {
    if (!this.disabled) {
      if (this.route) {
        this.$router.push({ name: this.route })
      } else if (this.to) {
        this.$router.push(this.to)
      }
      this.fireClick(evt)
    }
  }

  @Emit('click')
  protected fireClick(evt: Event) {
    return evt
  }
}
