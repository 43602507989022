




























import { Component, Vue } from 'vue-property-decorator'
import constants from '@/constants'
import { EventBus } from '@/plugins/eventBus'
import { modalBeforeCloseHandling, modalBeforeOpenHandling } from '@/services/functions'

@Component
export default class ErrorMessageModal extends Vue {
  name = constants.modalNames.ERROR_MESSAGE_MODAL
  errorMessage = ''
  closeCallbackFn = () => false

  onClose() {
    this.$modal.hide(this.name)
    this.closeCallbackFn()
  }

  created() {
    EventBus.$on(constants.eventNames.SHOW_ERROR_MESSAGE, (payload: { message: string, onCloseCallbackFn: Function }) => {
      this.errorMessage = payload.message || this.$t('errorMessageModal.defaultPopUpErrorMessage').toString()

      this.closeCallbackFn = () => payload.onCloseCallbackFn()
      this.$nextTick(() => this.$modal.show(this.name))
    })
  }

  destroyed() {
    EventBus.$off(constants.eventNames.SHOW_ERROR_MESSAGE)
  }

  private restorePoint = 0

  beforeOpen() {
    this.restorePoint = document?.documentElement?.scrollTop || 0
    modalBeforeOpenHandling()
  }

  beforeClose() {
    modalBeforeCloseHandling(this.restorePoint)
  }
}
