









import { Component, Vue } from 'vue-property-decorator'
import store from '@/store'
import { ACTION_LOGOUT } from '@/store/modules/user/actionTypes'
import constants from '@/constants'
import { Config } from '@/config'

@Component({})
export default class extends Vue {
  onidle() {
    store.dispatch(ACTION_LOGOUT).then(() => {
      this.$router.replace({ name: constants.routeNames.EXPIRE })
    })
  }

  getDuration() {
    return Config.inactivityTimeOutInSec
  }
}
