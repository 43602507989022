





















































import { Component, Vue } from 'vue-property-decorator'
import constants from '@/constants'
import { modalBeforeCloseHandling, modalBeforeOpenHandling } from '@/services/functions'

@Component
export default class TermAndConditionNoticeModal extends Vue {
  name = constants.modalNames.TNC_NOTICE_MODAL

  goToContactUs() {
    if (this.$router.currentRoute.name !== constants.routeNames.CONTACT_US_EMAIL) {
      this.$router.push({ name: constants.routeNames.CONTACT_US_EMAIL })
    }
    this.onCancel()
  }

  onCancel () {
    this.$modal.hide(this.name)
  }

  async onConfirm () {
    this.$modal.hide(this.name)
  }

  private restorePoint = 0

  beforeOpen() {
    this.restorePoint = document?.documentElement?.scrollTop || 0
    modalBeforeOpenHandling()
  }

  beforeClose() {
    modalBeforeCloseHandling(this.restorePoint)
  }
}
