import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { Config } from '@/config'

Vue.use(VueI18n)

const loadLocaleMessages = () => {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  // eslint-disable-next-line
  const messages: any = {}
  locales.keys().forEach(key => {
    const match = key.match(/([A-Za-z0-9-_]+)/i)
    if (match && match.length > 1) {
      const locale = match[1]
      messages[locale] = messages[locale] || {}
      messages[locale] = { ...messages[locale], ...locales(key) }
    }
  })
  return messages
}

export default new VueI18n({
  locale: Config.locale,
  fallbackLocale: Config.fallbackLocale,
  messages: loadLocaleMessages()
})
