import constants from '@/constants'
import { RouteConfig } from 'vue-router'

export const userProfileRoutes: Array<RouteConfig> = [
  {
    path: '/auth/updateMyInfo',
    name: constants.routeNames.UPDATE_MY_INFO,
    component: () =>
      import(/* webpackChunkName: "updateMyInfo" */ '@/views/auth/UserProfile/UpdateMyInfo.vue'),
    meta: {
      pageIndex: 1
    }
  },
  {
    path: '/auth/updateMyInfoVerify',
    name: constants.routeNames.UPDATE_MY_INFO_VERIFY,
    component: () =>
      import(/* webpackChunkName: "updateMyInfo" */ '@/views/auth/UserProfile/UpdateMyInfoVerify.vue'),
    meta: {
      pageIndex: 1.1
    }
  },
  {
    path: '/auth/updateMyInfoSuccess',
    name: constants.routeNames.UPDATE_MY_INFO_SUCCESS,
    component: () =>
      import(
        /* webpackChunkName: "updateMyInfoSuccess" */ '@/views/auth/UserProfile/UpdateMyInfoSuccess.vue'
      ),
    meta: {
      pageIndex: 1.2
    }
  }
]
