import constants from './'
import { InfoModalDescription } from '@/interfaces'
import { warrantyPhotoModalDef } from '@/constants/modaldefs/warrantyPhotoModalDef'
import { pocPhotoModalDef } from '@/constants/modaldefs/pocPhotoModalDef'

export function getModalContent(modalId: string, $t: any, suffix = ''): InfoModalDescription | null {
  switch (modalId) {
    case 'photofront':
      return {
        name: constants.modalNames.INFO_SERIAL_FRONT_MODAL + suffix,
        lines: [{
          type: 'h1',
          text: `<span class="text">${$t('infoModal.photoFront.title')}</span>`
        },
        {
          type: 'h2',
          text: $t('infoModal.photoFront.heading.step1')
        },
        {
          type: 'p',
          text: $t('infoModal.photoFront.content.step1', ['<span class="icon-settings"></span>'])
        },
        {
          type: 'img',
          imageSrc: require('@/assets/front-step-1.png')
        },
        {
          type: 'h2',
          text: $t('infoModal.photoFront.heading.step2')
        },
        {
          type: 'p',
          text: $t('infoModal.photoFront.content.step2')
        },
        {
          type: 'img',
          imageSrc: require('@/assets/front-step-2.png')
        },
        {
          type: 'h2',
          text: $t('infoModal.photoFront.heading.step3')
        },
        {
          type: 'p',
          text: $t('infoModal.photoFront.content.step3')
        },
        {
          type: 'img',
          imageSrc: require('@/assets/front-step-3.png')
        },
        {
          type: 'aside',
          text: `<img src="${require('@/assets/icon-diamond-warning.svg')}"/><span><h1>${$t('infoModal.photoFront.heading.precautions')}</h1></span>`
        },
        {
          type: 'ul',
          class: 'bullet',
          text:
`
<li><span>${$t('infoModal.photoFront.content.precaution1')}</span></li>
<li><span>${$t('infoModal.photoFront.content.precaution2')}</span></li>
<li><span>${$t('infoModal.photoFront.content.precaution3')}</span></li>
`
        }]
      }
    case 'photoback':
      return {
        name: constants.modalNames.INFO_SERIAL_BACK_MODAL,
        lines: [{
          type: 'h1',
          text: `<span class="text">${$t('infoModal.photoBack.title')}</span>`
        },
        {
          type: 'p',
          text: $t('infoModal.photoBack.content.step1')
        },
        {
          type: 'img',
          imageSrc: require('@/assets/back-step.png')
        },
        {
          type: 'aside',
          text: `<img src="${require('@/assets/icon-diamond-warning.svg')}"/><span><h1>${$t('infoModal.photoBack.heading.precautions')}</h1></span>`
        },
        {
          type: 'ul',
          class: 'bullet',
          text: `<li>${$t('infoModal.photoBack.content.precaution1')}</li><li>${$t('infoModal.photoBack.content.precaution2')}</li>`
        }]
      }
    case 'warrantycard':
      return warrantyPhotoModalDef($t)
    case 'receipt':
      return pocPhotoModalDef($t)
    default:
      return null
  }
}
