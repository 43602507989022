interface Config {
  sentry: {
    dsn: string;
  }
  environment: string;
  publicPath: string;
  origin: string;
  locale: string;
  fallbackLocale: string;
  surveyLink: string;
  shift: {
    guestAuthUrl: string;
    otpAuthUrl: string;
    url: string;
  };
  supportContact: {
    phoneNumber: string
  }
  ocr: {
    factOcrMap: any;
    ocrFacts: string[];
  };
  gmo: {
    useRedirect: boolean;
    successUrl: string;
    cancelUrl: string;
    redirectCompletionCallbackUrl: string;
    redirectCookieTimeoutSeconds: number;
  };
  maxDevices: number;
  customFields: {
    shippingCompany: string;
  }
  inactivityTimeOutInSec: number;
  appUrl: string;
}

// eslint-disable-next-line
const publicPath = __webpack_public_path__

const ocrFacts: string[] = ['COMPONENT:base.deviceregistration.photofront']

const factOcrMap: any = {
  consoleSerialNum: 'COMPONENT:base.deviceregistration.photofront.serialbody',
  consoleSerialNumEdited: 'COMPONENT:base.deviceregistration.photofront.serialbody.edited',
  joyconLeftSerialNum: 'COMPONENT:base.deviceregistration.photofront.serialjoyconleft',
  joyconLeftSerialNumEdited: 'COMPONENT:base.deviceregistration.photofront.serialjoyconleft.edited',
  joyconRightSerialNum: 'COMPONENT:base.deviceregistration.photofront.serialjoyconright',
  joyconRightSerialNumEdited: 'COMPONENT:base.deviceregistration.photofront.serialjoyconright.edited'
}

export const Config: Config = {
  environment: process.env.NODE_ENV || 'development',
  sentry: {
    dsn: 'https://055725084cd248d0b97f62b98e23ce8f@o163550.ingest.sentry.io/5965704'
  },
  surveyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSe5PrxblXuBxY_ERpPjxxKe7Ky1S70Mwrull4Y6Ud2uZ1AUMw/viewform',
  supportContact: {
    phoneNumber: '0570-037-555'
  },
  publicPath,
  origin: window.origin,
  locale: process.env.VUE_APP_I18N_LOCALE || 'ja',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ja',
  shift: {
    guestAuthUrl:
      process.env.VUE_APP_SHIFT_GUEST_AUTH_URL ||
      `${window.origin}/v2/auth/guest`,
    otpAuthUrl:
      process.env.VUE_APP_SHIFT_OTP_AUTH_URL ||
      `${window.origin}/v2/auth/login`,
    url: process.env.VUE_APP_SHIFT_URL || window.origin
  },
  ocr: {
    ocrFacts,
    factOcrMap
  },
  gmo: {
    useRedirect: true,
    successUrl: window.location.origin + '/v2/policy/paymentprovider/redirect',
    // eslint-disable-next-line
    cancelUrl: window.location.origin + __webpack_public_path__ + 'store-memberid-callback/index.html',
    // eslint-disable-next-line
    redirectCompletionCallbackUrl: window.location.origin + __webpack_public_path__ + 'store-memberid-callback/index.html',
    // at least longer than jwt token
    redirectCookieTimeoutSeconds: 21601
  },
  maxDevices: 5,
  customFields: {
    shippingCompany: 'https://toi.kuronekoyamato.co.jp/cgi-bin/tneko'
  },
  inactivityTimeOutInSec: 1800, // 30 min
  // eslint-disable-next-line
  appUrl: window.location.origin + __webpack_public_path__.slice(0, -1)
}
