import { ConvertQuoteModel, PolicyBundleModel } from 'shift-policy-service-api-client'
import { ActionTree, ActionContext } from 'vuex'
import { ProductState } from '@/store/api/ProductState'
import { RootState } from '@/store/api/RootState'
import { Shift } from '@/services/Shift'
import {
  SET_PRODUCT_MODEL,
  SET_SALES,
  SET_PRODUCT_LOADED,
  SET_FACT_VALUE,
  SET_VALIDATE,
  SET_SERIALIZED_CONFIG,
  SET_PRODUCT_STATE,
  SET_INVOICE_ID,
  RESET_SALE_JOURNEY_STATE,
  SET_JOURNEY_STATE_PROPOSAL_SUBMITED,
  SET_JOURNEY_STATE_QUOTE_CREATED,
  SET_JOURNEY_STATE_POLICY_CREATED,
  SET_JOURNEY_STATE_INVOICE_AVAILABLE,
  SET_JOURNEY_STATE_PAYMENT_STARTED,
  SET_JOURNEY_STATE_PAYMENT_COMPLETED,
  SET_BUNDLE,
  SET_CONVERT_QUOTE,
  SET_JOURNEY_STATE_GMO_OK
} from './mutationTypes'
import { GroupName, GroupNames, Sales } from '@/services/Sales'
import { FactValueChange } from '@/store/api/models/FactValueChange'
import { Auth } from '@/services/Auth'
import { initialState } from './initialState'
import store from '@/store'
import { dformat } from '@/services/functions'
import { Facts } from '@/constants/common'
import { ACTION_SET_EMAIL } from '../user/actionTypes'

type ProductContext = ActionContext<ProductState, RootState>

export const actions: ActionTree<ProductState, RootState> = {
  async loadProduct({ commit, state }: ProductContext): Promise<void> {
    if (!state.loaded) {
      await Auth.waitForToken('otp')
      const product = await Shift.getProduct()
      const config = state.serializedConfig || undefined
      const sales = new Sales(product.definition, config)
      // By default, add to cart.
      if (!config) {
        sales.cart.addToCart(sales.catalog.products[0])
      } else {
        sales.cart.addExistingToCart(sales.catalog.products[0])
      }
      commit(SET_SALES, sales)
      commit(SET_PRODUCT_MODEL, product)
      commit(SET_PRODUCT_LOADED, true)
    }
  },
  setProductLoaded({ commit }: ProductContext, payload: boolean) {
    commit(SET_PRODUCT_LOADED, payload)
  },
  resetProductSerializedConfig({ commit }: ProductContext) {
    commit(SET_SERIALIZED_CONFIG, null)
  },
  factValueSet(
    { commit, state, dispatch }: ProductContext,
    factChange: FactValueChange
  ) {
    if (
      factChange.groupName === GroupNames.email &&
      factChange.factId === Facts.product.email
    ) {
      dispatch(ACTION_SET_EMAIL, factChange.value, { root: true })
    }
    // Set fact value
    commit(SET_FACT_VALUE, factChange)
    // Trigger state changing validation
    commit(SET_VALIDATE, factChange.groupName)
    // Store serialized config
    const serializedConfig =
      state.sales?.cart.contents[0].productEngine.serializeConfig() || null
    commit(SET_SERIALIZED_CONFIG, serializedConfig)
  },
  validate({ commit }: ProductContext, groupName: GroupName) {
    commit(SET_VALIDATE, groupName)
  },
  emptyCart({ state }: ProductContext) {
    if (state.sales) {
      state.sales.cart.emptyCart()
      state.sales.catalog.products[0].newSession()
    }
  },
  // Empties cart and re-adds an empty product
  async newCoverage({ state }: ProductContext): Promise<void> {
    if (state.sales) {
      const cart = state.sales.cart
      const catalog = state.sales.catalog
      cart.emptyCart()
      cart.addToCart(catalog.products[0])
      // Pre-fill fields
      if (Auth.loggedInAs('otp')) {
        const policyHolder = await Shift.getPolicyHolderDetail()
        const pe = state.sales.cart.contents[0].productEngine
        // Set email
        pe.setFactValue('applicant.email', store.state.user.email)
        if (policyHolder) {
          // Set firstnamekana
          pe.setFactValue('applicant.firstname', policyHolder.givenName)
          // Set lastnamekana
          pe.setFactValue('applicant.lastname', policyHolder.familyName)
          // Set dob, if present
          if (policyHolder.dateOfBirth) {
            pe.setFactValue('applicant.dob', dformat(policyHolder.dateOfBirth))
          }
        }
      }
    }
  },
  resetState({ commit }: ProductContext) {
    commit(SET_PRODUCT_STATE, { ...initialState })
  },
  setInvoiceId({ commit }: ProductContext, invoiceId: string) {
    commit(SET_INVOICE_ID, invoiceId)
  },
  setBundle({ commit }: ProductContext, bundle: PolicyBundleModel | null) {
    commit(SET_BUNDLE, bundle)
  },
  setConvertQuote({ commit }: ProductContext, convertQuote: ConvertQuoteModel | null) {
    commit(SET_CONVERT_QUOTE, convertQuote)
  },
  setLanguage({ state }: ProductContext, language: string) {
    if (state.sales) {
      state.sales.cart.setLanguage(language)
      state.sales.catalog.setLanguage(language)
    }
  },
  resetSaleJourneyState({ commit }: ProductContext) {
    commit(RESET_SALE_JOURNEY_STATE)
  },
  setSaleJourneyStateProposalSubmitted({ commit }: ProductContext, payload: boolean) {
    commit(SET_JOURNEY_STATE_PROPOSAL_SUBMITED, payload)
  },
  setSaleJourneyStateQuoteCreated({ commit }: ProductContext, payload: boolean) {
    commit(SET_JOURNEY_STATE_QUOTE_CREATED, payload)
  },
  setSaleJourneyStatePolicyCreated({ commit }: ProductContext, payload: boolean) {
    commit(SET_JOURNEY_STATE_POLICY_CREATED, payload)
  },
  setSaleJourneyStateInvoiceAvailable({ commit }: ProductContext, payload: boolean) {
    commit(SET_JOURNEY_STATE_INVOICE_AVAILABLE, payload)
  },
  setSaleJourneyStatePaymentStarted({ commit }: ProductContext, payload: boolean) {
    commit(SET_JOURNEY_STATE_PAYMENT_STARTED, payload)
  },
  setSaleJourneyGmoOK({ commit }: ProductContext, payload: boolean) {
    commit(SET_JOURNEY_STATE_GMO_OK, payload)
  },
  setSaleJourneyStatePaymentCompleted({ commit }: ProductContext, payload: boolean) {
    commit(SET_JOURNEY_STATE_PAYMENT_COMPLETED, payload)
  }
}
