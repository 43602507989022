import { PolicyState } from '@/store/api/PolicyState'
import { RootState } from '@/store/api/RootState'
import { Module } from 'vuex'
import { initialState } from './initialState'
import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'

const state = { ...initialState }

export const policy: Module<PolicyState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
