import { Config } from '@/config'
import * as Sentry from '@sentry/browser'
import { Event } from '@sentry/browser'
import * as SentryIntegrations from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

// only init sentry on production environment
// known issue (infinite loop on development mode) occur during error template binding
if (Config.environment === 'production') {
  const envMatch = window.location.host.match(/(.*)\.[a-z-]*\.[a-z]*/)
  Sentry.init({
    dsn: Config.sentry.dsn,
    integrations: [
      new Integrations.BrowserTracing(),
      // generate sentry event for calls to console.error
      new SentryIntegrations.CaptureConsole({ levels: ['error'] })
    ],
    release: `coverhub-aon-nintendo@${process.env.VUE_APP_VERSION}`,
    environment: envMatch ? envMatch[1] : 'unknown-env',
    tracesSampleRate: 1.0,
    beforeSend(event: Event): PromiseLike<Event | null> | Event | null {
      // skip 401s
      if (
        event?.message?.match(/.*Request failed with status code 401.*/) ||
        event?.tags?.ApiError === 401 ||
        event?.exception?.values?.find(value => value.value?.match(/.*Request failed with status code 401.*/))
      ) {
        return null
      } else {
        return event
      }
    }
  })
}

export const sentryErrorHandler = (err: any) => {
  let tags: any = { AppError: true }
  const contexts: any = {}
  if (err.isAxiosError) {
    tags = {
      ApiErrorStatusText: err?.response?.statusText || 'unknown-error-status-text',
      ApiError: err?.response?.status || 'unknown-error-status'
    }
    contexts.error = {
      errorType: err?.response?.data?.error?.type || 'unknown-error-type',
      errorMessage: err?.response?.data.error.message || 'unknown-error-message'
    }
  }

  Sentry.captureException(err, {
    tags,
    contexts
  })
}
