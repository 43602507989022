export default {
  FADE: 'fade',
  ZOOM: 'zoom',
  SLIDE_LEFT: 'slide-left',
  SLIDE_RIGHT: 'slide-right',
  RIGHT_SLIDE_IN: 'right-slide-in',
  RIGHT_SLIDE_OUT: 'right-slide-out',
  LEFT_SLIDE_IN: 'left-slide-in',
  LEFT_SLIDE_OUT: 'left-slide-out'
}
