export default {
  HOME: 'Home',
  ERROR: 'ERROR',
  EXPIRE: 'EXPIRE',
  DASHBOARD: 'Dashboard',
  CONTACT_US: 'contactUs',
  CONTACT_US_EMAIL: 'contactUsEmail',
  CONTACT_US_EMAIL_SUCCESS: 'contactUsEmailSuccess',
  SIGN_IN: 'signIn',
  POLICY_LIST: 'policyList',
  POLICY_DETAIL: 'policyDetail',
  POLICY_CERTIFICATE: 'POLICY_CERTIFICATE',
  BEFORE_CLAIM: 'beforeClaim',
  CLAIM_LIST: 'claimList',
  CLAIM_DETAIL: 'claimDetail',
  CLAIM_SHIPPING: 'claimShipping',
  CLAIM_SUMMARY: 'claimSummary',
  CLAIM_SUCCESS: 'claimSuccess',
  VIEW_CLAIM_LIST: 'viewClaimList',
  VIEW_CLAIM_DETAIL: 'viewClaimDetail',
  APPLICATION: 'APPLICATION',
  APPLICATION_EMAIL: 'APPLICATION_EMAIL',
  APPLICATION_OTP: 'APPLICATION_OTP',
  APPLICATION_DEVICE: 'APPLICATION_DEVICE',
  APPLICATION_PLAN: 'APPLICATION_PLAN',
  APPLICATION_CERT: 'APPLICATION_CERT',
  APPLICATION_FORM: 'APPLICATION_FORM',
  APPLICATION_SUMMARY: 'APPLICATION_SUMMARY',
  APPLICATION_PAYMENT: 'APPLICATION_PAYMENT',
  APPLICATION_COMPLETED: 'APPLICATION_COMPLETED',
  UPDATE_MY_INFO: 'UPDATE_MY_INFO',
  UPDATE_MY_INFO_VERIFY: 'UPDATE_MY_INFO_VERIFY',
  UPDATE_MY_INFO_SUCCESS: 'UPDATE_MY_INFO_SUCCESS',
  CHANGE_PLAN: 'CHANGE_PLAN',
  CHANGE_PLAN_SUCCESS: 'CHANGE_PLAN_SUCCESS',
  CHANGE_PLAN_FAILURE: 'CHANGE_PLAN_FAILURE',
  CANCEL_COVERAGE: 'CANCEL_COVERAGE',
  CANCEL_COVERAGE_SUCCESS: 'CANCEL_COVERAGE_SUCCESS',
  BEFORE_APPLICATION: 'BEFORE_APPLICATION',
  SITE_POLICY: 'SITE_POLICY',
  ACCOUNT_POLICY: 'ACCOUNT_POLICY',
  TERMS_OF_USE: 'TERMS_OF_USE',
  NOTICES: 'NOTICES',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  MOBILE_ONLY: 'MOBILE_ONLY',
  NOT_FOUND: 'NOT_FOUND',
  DENIED_CAMERA: 'DENIED_CAMERA'
}
