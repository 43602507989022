













import constants from '@/constants'
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component({
  name: 'TermAndConditionUpdateNotice'
})
export default class TermAndConditionUpdateNotice extends Vue {
  @Getter('app/showTermAndCondition')
  showTermAndCondition!: boolean

  openTNCModal() {
    this.$modal.show(constants.modalNames.TNC_NOTICE_MODAL)
  }

  openTNCServiceModal() {
    this.$modal.show(constants.modalNames.TNC_SERVICE_MODAL)
  }
}
