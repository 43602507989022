import { otpXhr } from '@/services/Auth'
import { Config } from '@/config'
import { V2QuoteApi, V2QuoteApiInterface } from 'shift-quote-service-api-client'
import { ApiFactory } from '@uncharted/coverhub-framework'

export const Quotes = ApiFactory<V2QuoteApiInterface, V2QuoteApi>(
  V2QuoteApi,
  Config.shift.url,
  otpXhr
)
