import { Shift } from '@/services/Shift'
import { ClaimState } from '@/store/api/ClaimState'
import { RootState } from '@/store/api/RootState'
import { ActionContext, ActionTree } from 'vuex'
import {
  SET_CLAIM_STATE,
  SET_CLAIM_OVERLAYS,
  SET_REPAIR_POLICY_MODEL,
  SET_CLAIM_VALIDATE,
  SET_NEW_SERIALIZED,
  SET_SUBMITTED_CLAIM,
  SET_REPAIRS,
  SET_FACTS,
  SET_CONSENT
} from './mutationTypes'
import store from '@/store'
import { initialState } from './initialState'
import { FactValueChange } from '@/store/api/models/FactValueChange'
import { ACTION_CLAIM_DESCRIBE_FACTS, ACTION_RESET_CLAIM_STATE } from './actionTypes'
import { SubmittedClaim } from '@/store/api/models/SubmittedClaim'
import { ClaimsService } from '@/services/ClaimsService'
import { shift } from '@uncharted/coverhub-framework'
import { doutformat } from '@/services/functions'
import moment from 'moment-timezone'

import ValidationResponse = shift.claimengine.engine.validation.ValidationResponse

type ClaimContext = ActionContext<ClaimState, RootState>

export const actions: ActionTree<ClaimState, RootState> = {
  async loadClaim(
    { commit, state }: ClaimContext,
    policyId: string
  ): Promise<void> {
    const switchPolicyModel = store.state.policy.policies.find(
      (p) => p.policyId === policyId
    )
    if (switchPolicyModel) {
      const overlays = await ClaimsService.createClaimOverlays(
        policyId,
        switchPolicyModel.productId,
        switchPolicyModel._product,
        state.consent,
        state.serializedConfig,
        switchPolicyModel
      )
      commit(SET_CLAIM_OVERLAYS, overlays)
      commit(SET_CLAIM_VALIDATE, 'repair')
      commit(SET_REPAIR_POLICY_MODEL, switchPolicyModel)
    }
  },
  /**
   * Store user consent for this claim.
   */
  async consent({ commit }: ClaimContext, consent: boolean): Promise<void> {
    if (!consent) {
      throw new Error('consent can not be false')
    }
    commit(SET_CONSENT, 'true')
  },
  async submit({ commit, state }: ClaimContext): Promise<ValidationResponse> {
    const res = state.overlays[0].claim.claimEngine.validate()
    const failed = res.violations.find(v => v.type === 'FAIL')

    if (!failed && res.valid) {
      // eslint-disable-next-line
      const serializeConfig = state.serializedConfig!
      const claimDefinitionId = state.overlays[0].claim.meta.claimDefinitionId
      const policyId = state.overlays[0].claim.meta.policyId
      const submitted = await Shift.submitClaim(
        serializeConfig,
        claimDefinitionId,
        policyId
      )
      const submittedClaim: SubmittedClaim = {
        claimNumber: submitted.claimNumber,
        createdDate: doutformat(moment.tz(submitted.sfCreated, 'UTC').clone().tz('Asia/Tokyo'))
      }
      this.dispatch(ACTION_RESET_CLAIM_STATE)
      commit(SET_SUBMITTED_CLAIM, submittedClaim)
      return res
    } else {
      return res
    }
  },
  resetState({ commit }: ClaimContext): void {
    commit(SET_CLAIM_STATE, { ...initialState })
  },
  describeFacts({ commit, state }: ClaimContext, overlayName: string) {
    const overlay = state.overlays.find(o => o.name === overlayName)
    if (overlay) {
      const facts = overlay.describeFacts().facts
      commit(SET_FACTS, facts)
      commit(SET_CLAIM_VALIDATE, overlayName)
    }
  },
  async factValueSet(
    { commit, state, dispatch }: ClaimContext,
    factChange: FactValueChange
  ) {
    const overlay = state.overlays.find((o) => o.name === factChange.groupName)

    if (overlay) {
      await overlay.setFactValue(factChange.factId, factChange.value)
      const serializedConfig = state.overlays
        .find((o) => o.name === factChange.groupName)
        ?.serializeConfig()
      if (serializedConfig) {
        commit(SET_NEW_SERIALIZED, serializedConfig)
      }
      dispatch(ACTION_CLAIM_DESCRIBE_FACTS, factChange.groupName, { root: true })
      commit(SET_CLAIM_VALIDATE, factChange.groupName)
    }
  },
  async loadRepairs({ commit }: ClaimContext) {
    const claims = await Shift.loadRepairs()
    commit(SET_REPAIRS, claims)
  }
}
