














import constants from '@/constants'
import { EventBus } from '@/plugins/eventBus'
import { modalBeforeCloseHandling, modalBeforeOpenHandling } from '@/services/functions'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ImageViewer extends Vue {
  transitionName = constants.transitionNames.ZOOM
  isActive = false
  name = ''
  url = ''

  showViewer(data: ImageViewerParam) {
    this.restorePoint = document?.documentElement?.scrollTop || 0
    modalBeforeOpenHandling()
    this.name = data.name
    this.url = data.url
    this.isActive = true
  }

  closeViewer() {
    modalBeforeCloseHandling(this.restorePoint)
    this.isActive = false
    this.name = ''
    this.url = ''
    EventBus.$emit(constants.eventNames.SHOW_HEADER)
  }

  created() {
    EventBus.$on(constants.eventNames.SHOW_IMAGE_VIEWER, this.showViewer)
    EventBus.$on(constants.eventNames.CLOSE_IMAGE_VIEWER, this.closeViewer)
  }

  private restorePoint = 0

  beforeDestroy() {
    EventBus.$off(constants.eventNames.SHOW_HEADER)
    EventBus.$off(constants.eventNames.CLOSE_IMAGE_VIEWER)
  }
}

export interface ImageViewerParam {
  name: string;
  url: string;
}
