import constants from '@/constants'
import { RouteConfig } from 'vue-router'
import store from '@/store'
import {
  ACTION_LOAD_CLAIM,
  ACTION_CLAIM_LOAD_REPAIRS
} from '@/store/modules/claim/actionTypes'
import { ACTION_LOAD_POLICIES } from '@/store/modules/policy/actionTypes'

export const claimsRoutes: Array<RouteConfig> = [
  {
    path: '/auth/beforeClaim',
    name: constants.routeNames.BEFORE_CLAIM,
    component: () =>
      import(/* webpackChunkName: "claim" */ '@/views/auth/Claim/BeforeClaim.vue'),
    meta: {
      pageIndex: 2
    }
  },
  {
    path: '/auth/claim',
    component: () =>
      import(/* webpackChunkName: "claim" */ '@/views/auth/Claim/Index.vue'),
    meta: {
      pageIndex: 2.1
    },
    beforeEnter(to, from, next) {
      store.dispatch(ACTION_LOAD_POLICIES, true)
        .then(next)
        .catch(e => {
          console.error(e)
          next({ name: constants.routeNames.ERROR })
        })
    },
    children: [
      {
        path: '',
        name: constants.routeNames.CLAIM_LIST,
        meta: {
          step: 1
        },
        component: () =>
          import(/* webpackChunkName: "claim" */ '@/views/auth/Claim/ClaimList.vue')
      },
      {
        path: ':id',
        component: () =>
          import(
            /* webpackChunkName: "claim" */ '@/views/auth/Claim/ClaimIndex.vue'
          ),
        beforeEnter(to, _from, next) {
          store.dispatch(ACTION_LOAD_CLAIM, to.params.id)
            .then(next)
            .catch(e => {
              console.error(e)
              next({ name: constants.routeNames.ERROR })
            })
        },
        children: [
          {
            path: '',
            name: constants.routeNames.CLAIM_DETAIL,
            meta: {
              step: 2
            },
            component: () =>
              import(
                /* webpackChunkName: "claim" */ '@/views/auth/Claim/ClaimDetail.vue'
              )
          },
          {
            path: 'shipping',
            name: constants.routeNames.CLAIM_SHIPPING,
            meta: {
              step: 3
            },
            component: () =>
              import(
                /* webpackChunkName: "claim" */ '@/views/auth/Claim/ClaimShipping.vue'
              )
          },
          {
            path: 'summary',
            name: constants.routeNames.CLAIM_SUMMARY,
            meta: {
              step: 4
            },
            component: () =>
              import(
                /* webpackChunkName: "claim" */ '@/views/auth/Claim/ClaimSummary.vue'
              )
          }
        ]
      }
    ]
  },
  {
    path: '/auth/claimSuccess',
    name: constants.routeNames.CLAIM_SUCCESS,
    component: () =>
      import(/* webpackChunkName: "claim" */ '@/views/auth/Claim/ClaimSuccess.vue'),
    meta: {
      pageIndex: 2.2
    }
  },
  {
    path: '/auth/viewClaim',
    // name: 'ViewClaim',
    component: () =>
      import(/* webpackChunkName: "viewClaim" */ '@/views/auth/ViewClaim/Index.vue'),
    beforeEnter(to, from, next) {
      store.dispatch(ACTION_CLAIM_LOAD_REPAIRS)
        .then(next)
        .catch(e => {
          console.error(e)
          next({ name: constants.routeNames.ERROR })
        })
    },
    meta: {
      pageIndex: 1
    },
    children: [
      {
        path: '',
        name: constants.routeNames.VIEW_CLAIM_LIST,
        component: () =>
          import(
            /* webpackChunkName: "viewClaim" */ '@/views/auth/ViewClaim/ViewClaimList.vue'
          ),
        meta: {
          pageIndex: 1.1
        }
      },
      {
        path: ':id',
        name: constants.routeNames.VIEW_CLAIM_DETAIL,
        component: () =>
          import(
            /* webpackChunkName: "viewClaim" */ '@/views/auth/ViewClaim/ViewClaimDetail.vue'
          ),
        meta: {
          pageIndex: 1.2
        }
      }
    ]
  }
]
