import { PolicyState } from '@/store/api/PolicyState'

export const initialState: PolicyState = {
  policies: [],
  loaded: false,
  changePlan: null,
  cancelPlan: null,
  photos: null,
  policyCertificate: null,
  convertedPolicy: null
}
