import modalName from '../modalName'

export function pocPhotoModalDef($t: any) {
  return {
    name: modalName.INFO_POC_MODAL,
    lines: [
      {
        type: 'h1',
        text: `<span class="text">${$t('pocPhotoModal.title')}</span>`
      },
      {
        type: 'h2',
        text: $t('pocPhotoModal.steps.1.heading')
      },
      {
        type: 'p',
        text: $t('pocPhotoModal.steps.1.text')
      },
      {
        type: 'ul',
        class: 'bullet',
        text: `<li>${$t('pocPhotoModal.steps.1.list.1')}</li><li>${$t('pocPhotoModal.steps.1.list.2')}</li><li>${$t('pocPhotoModal.steps.1.list.3')}</li>`
      },
      {
        type: 'p',
        class: 'note',
        text: `<span class=asterix>*</span><span>${$t('pocPhotoModal.steps.1.note')}</span>`
      },
      {
        type: 'aside',
        text: `<img src="${require('@/assets/icon-diamond-warning.svg')}"/><span><h1>${$t('pocPhotoModal.steps.1.caution.heading')}</h1></span>`
      },
      {
        type: 'ul',
        class: 'bullet',
        text: `<li>${$t('pocPhotoModal.steps.1.caution.text')}</li>`
      }
    ]
  }
}
