



























import { Component, Vue } from 'vue-property-decorator'
import constants from '@/constants'
import { modalBeforeCloseHandling, modalBeforeOpenHandling } from '@/services/functions'

@Component
export default class TermOfUseModal extends Vue {
  name = constants.modalNames.NEW_TERM_OF_USE_MODAL

  content = `第1条（利用規約への同意）
    任天堂販売株式会社（以下「任天堂販売」といいます）は、ワイドケア for Nintendo Switch 保証利用規約（以下「本規約」といいます）に基づき保証サービス（以下「本サービス」といいます）を提供するものとします。お客様は、本サービスの利用申込にあたり本規約をご確認のうえ同意いただく必要があります。なお、本サービスの利用は、満18歳以上の日本に住所を有する方のみ行うことができます。
    \n第2条（用語の定義）
    (1)  本サービス対象製品 
    任天堂株式会社（以下「任天堂」といいます）が日本国内向けに製造したNintendo Switch™、Nintendo Switch（有機ELモデル）™およびNintendo Switch Lite™の本体、当該本体に同梱されるJoy-Con™(L)/(R)、Nintendo Switchドック、並びにACアダプター(ただし、任天堂が製造していないものおよび第三者による改造その他の工作が加えられたものを除く) 
    (2)  サービス利用契約
    本規約に基づき任天堂販売とお客様との間で締結する本サービスの利用に関する契約
    (3)  登録ゲーム機
    お客様が本サービスの利用申込の際に指定され、エーオンジャパンが管理する顧客管理システムにシリアルナンバーが登録された本サービス対象製品であり、保証対象事故が発生した際に本サービスによる保証の適用を受けることができる本サービス対象製品。ただし、Nintendo Switchドックについては、シリアルナンバーの登録をせずとも、Nintendo SwitchおよびNintendo Switch（有機ELモデル）の本体に同梱されているものに限り、登録ゲーム機として取り扱います。なお、本条第7号（交換対応）に基づき登録ゲーム機のシリアルナンバーが変更された場合は、変更後の本サービス対象製品を登録ゲーム機とします。
    (4)  加入者
    本規約に同意し、任天堂販売が指定する方法にて登録ゲーム機を登録し本サービスの利用料金を支払うお客様
    (5)  保証
    本サービス加入後に故障または破損した登録ゲーム機に対し、修理対応を提供し、または、修理対応に代えて、もしくはそれと同時に交換対応を任天堂販売から加入者に提供すること
    (6)  修理対応
    加入者の所有する登録ゲーム機に保証対象事故が発生し、加入者が本規約に指定する方法で保証の申請を行った場合に、保証対象事故の発生した登録ゲーム機を回収し任天堂が定める基準まで機能を回復するために部品の修理を行うこと
    (7)  交換対応
    任天堂販売の判断に従い、修理対応のうち、登録ゲーム機のシリアルナンバー（Joy-Con(L)/(R)のものを除く）の変更を伴う修理を行うこと
    (8)  保証対象事故
    登録ゲーム機が正常にご利用いただけない状態となった原因のうち、保証を受けることができる種類の事故等として本規約で定めるもの
    (9)  利用開始時点
    任天堂販売とお客様との間でサービス利用契約が成立し、対象となる本サービス対象製品が登録され、加入者が本サービスの利用料金の支払いを開始した日として、任天堂販売がサービスサイトに定める方法で加入者に通知した年月日
    (10)  本サービスアカウント
    本サービスの加入申込を行うにあたり必要となる任天堂販売が運営するワイドケア for Nintendo Switchアカウント。本サービスアカウントの利用規約については別途定めるものとします。
    (11)  サービスサイト
    任天堂販売が本サービスおよび本サービスアカウントなど関連する事象の説明や情報および各種手続きについて明示しているWebサイト
    (12)  月額プラン
    利用開始時点から1か月の保証対象期間となる支払金額200円（消費税込）の料金プラン。（1台当たりの料金です）
    (13)  年額プラン
    利用開始時点から12か月の保証対象期間となる支払金額2000円（消費税込）の料金プラン。（1台当たりの料金です）
    (14)  免責期間
    利用申込を行うにあたり、登録しようとする本サービス対象製品について、利用申込の時点において、メーカー保証期間外である場合または任天堂販売がメーカー保証期間内であることが確認できない場合に設けられる期間（1か月）。免責期間は、保証対象期間（12か月）に含まれるものの、免責期間中に発生した事故については保証のお申込はできません。免責期間は、月額プランを申し込んだ場合には設定されません。
    (15)  自然故障
    取扱説明書、添付ラベル等の注意書に則した正常なご使用状態の下で発生した故障
    (16)  エーオンジャパン
    任天堂販売が本サービスの運営業務を委託する委託先であるエーオンジャパン株式会社（住所：東京都千代田区永田町2丁目10番）およびその関係会社
    \n第3条（サービス概要）
    任天堂販売は、加入者に対して保証対象期間中に登録ゲーム機に保証対象事故が生じた際に、加入者の申出に基づき保証を行います。ただし、免責期間が設定されている場合はその期間を除きます。
    \n第4条（申込条件）
    お客様は、本サービスの利用申込にあたり、お申込時点において次の各号に定める申込条件を満たしていただく必要があります。
    ①本サービス対象製品をお客様または同居されているご家族が所有されていること
    ②本サービス対象製品が紛失または盗難の被害に遭ったものではないこと
    ③お申込時において本サービス対象製品が正常にご利用いただける状態であること
    ④本サービスアカウントを保有していること
    ⑤暴力団、暴力団員、暴力団関係者、暴力団関係企業、暴力団関係団体、総会屋、社会運動標ぼうゴロその他暴力、威力、詐欺的手法を駆使して経済的利益を追求する集団もしくは個人またはこれらに準じる者でないこと
    ⑥月額プランで加入を希望するお客様の登録ゲーム機が、お申込時点でメーカー保証期間中であることを確認できる任天堂販売が指定する書類を提出いただき、任天堂販売が当該事実の確認ができること
    ⑦法人または本サービスをお客様の業務として用いることを目的としている個人ではないこと
    ２  任天堂販売は次の各号のいずれかに該当するお客様の加入をお断りする場合があります。
    ①お申込の申告事項に虚偽の記載、誤記、記入漏れがあった場合 
    ②過去に本サービスの利用料金の未払い、本サービスの利用停止などがある場合
    ③お客様が本サービス対象製品を販売または転売などを目的として保有する場合
    ④任天堂販売が、前各号に該当するおそれがあると判断した場合
    ⑤その他任天堂販売が不適当な事由があると判断した場合
    \n第5条（加入申込方法）
    本サービスの加入申込は、本サービスサイトに定める方法に従って行います。
    ２  登録ゲーム機が、お申込時点でメーカー保証期間中であることを任天堂販売が確認できる場合は、月額プランおよび年額プランのいずれかを選択してお申込いただけます。任天堂販売がメーカー保証期間中であることが確認できない場合は年額プランのみお申込いただけます。
    ３  任天堂販売は、お申込内容を審査し、お客様に対して申込手続きの完了を通知します。なお、通知がお客様に到達した時点で、任天堂販売との間に登録ゲーム機についてサービス利用契約が成立するものとします。
    \n第6条（変更事項の届出）
    加入者は、お申込時にご提出いただいたお客様に関する情報に変更が生じた場合は、任天堂販売が別途本サービスサイトに定める方法にて速やかにエーオンジャパンその他の届け出が必要な団体に対しその変更を届け出るものとします。
    \n第7条（登録ゲーム機の変更）
    加入者は、保証期間中、登録ゲーム機の変更を行うことはできません。
    \n第8条（債権の譲渡等）
    任天堂販売は加入者が支払いを遅滞した本サービスの利用料金の債権を、任天堂販売が定める金融機関その他の第三者（以下「請求事業者」といいます）に譲渡することができ、加入者はこれをあらかじめ承諾します。この場合において、任天堂販売および請求事業者は、加入者への譲渡承諾の請求を省略するものとします。
    \n第9条（支払い）
    加入者は、選択した料金プランに応じて本サービスの利用料金を所定の支払期日までに支払います。
    ２  任天堂販売が実施するキャンペーンや割引プランなどが適用される場合があります。キャンペーンや割引プランの内容は各々に定めるものとします。
    ３  任天堂販売は、事前に加入者に通知または周知することにより、本サービスの利用料金の一部または全部を変更することができるものとします。この場合、変更日が属する月以降は変更後のご利用料金が適用されるものとします。
    ４  月額プランおよび年額プランのいずれであるかを問わず、加入者が本サービスを更新するときは、本サービスの利用料金を、加入者が選択した料金プランに応じて、当該プランの期間の満了日から起算して14日前までに支払うものとします。
    \n第10条（精算方法）
    任天堂販売（請求事業者の場合を含みます）は、本サービスの利用料金を加入者が指定した決済サービス会社などを経由して請求します。
    \n第11条（通信料の負担）
    お客様から本サービスの利用申込または加入者への本サービスの提供に関して、お客様が登録されたEmailアドレスに対し、電子メールをお送りする場合があります。
    ２  前項に基づきお客様にお送りする電子メールの受信にかかる通信料、その他本サービスの利用申込または本サービスの利用に際し発生する通信料は、お客様のご負担となります。
    \n第12条（保証の上限）
    加入者は、申込手続きが完了した時点を起算日として1年間に、登録ゲーム機1台につき10万円（消費税込み）を費用の上限としたうえでサービス利用契約に基づき登録ゲーム機に対する保証による修理対応を6回、また、そのうち保証による修理対応に代えて、またはそれと同時に登録ゲーム機の交換対応は2回まで受けられます。（なお、一度の保証において、修理対応および交換対応の両方が生じた場合は、交換対応がなされたものとして算入します。）
    ２  前項における保証の費用には、部品代（交換対応を行った部品等の費用を含む）、工賃、送料が含まれます。なお、「送料」とは、任天堂販売が保証対象事故の発生した登録ゲーム機を加入者より任天堂販売指定の方法で回収するための送料、および修理対応または交換対応もしくはその両方が終了した登録ゲーム機を、加入者へ返送にかかる費用を指します。ただし、加入者の都合およびお申し出内容の不備により、任天堂販売から発送された物品の受け取りがなされず、再度の配送にかかる費用については、「送料」に含みません。
    ３  費用の金額または修理対応回数（交換対応回数を含む）のどちらかの上限に到達している場合は、本サービスをご利用いただくことができません。なお、実際に修理対応を行うかどうかに関わらず、保証の申請を行った時点で、修理対応回数に算入されます。
    ４  第16条第2項に基づく登録ゲーム機の変更がなされた場合でも、保証の期間、修理対応回数、および交換対応回数はリセットされません。
    ５  保証の費用の目安額は任天堂が別途定める「修理の参考価格」によることとし、保証対応後に実際に発生した費用を加入者に通知するものとします。
    \n第13条（保証対象事故）
    任天堂Webサイトのサポートページで公表されている「安全に使用するために（PDF）」に記載された使用方法に則した状態で使用された登録ゲーム機につき、日本国内で発生した自然故障、落下による破損、外来の原因による破損、水濡れ、その他偶発的な事故による登録ゲーム機の全損または一部の破損を保証対象事故とします。
    \n第14条（保証の対象とはならない場合）
    前条にかかわらず、以下に該当する場合およびそれらを原因とした故障および破損は保証の対象とはなりません。
    ・本サービスをご利用にならずに登録ゲーム機を修理されるとき。またその修理に必要となる費用
    ・登録ゲーム機に添付ラベルがない、または剥がされた痕跡がある場合
    ・登録ゲーム機と実際にお送りいただいた本サービス対象製品に相違がある場合
    ・任天堂が本サービス対象製品についてリコール宣言を行った後のリコールの原因となった部位にかかる修理
    ・任天堂販売または任天堂が故障症状の確認を行った際、加入者が申し出た故障症状が再現しない場合、または本サービスの保証対象外の原因による故障であることが判明した場合の修理技術費用その他の費用
    ・本サービス対象製品に含まれない付属品および周辺機器（例：Joy-Conストラップ、HDMIケーブル、Nintendo Switch Proコントローラー、外部記憶媒体など）の故障または損傷および相性に起因した故障または損傷
    ・登録ゲーム機の故障または損傷に起因して生じた身体的損害（傷害に起因する死亡および経済的または精神的損害を含む)、財産的損害、逸失利益その他の損害
    ・登録ゲーム機の消耗、変質、変色等による損害（電池パックまたはバッテリーの消耗を含みます）、または傷、汚れ、塗装の剥離等の外見上の損害であって、登録ゲーム機の機能に影響が生じていないものであるとき
    ・登録ゲーム機が加工、改造、解析（ソフトウェアの改造、解析（ルート化等を含みます）、リバースエンジニアリング、逆コンパイル、または逆アセンブルを含みます）されたもの、または任天堂販売もしくは任天堂が指定する正規の修理拠点以外で分解もしくは修理されたものであると判断したとき
    ・加入者または加入者より正当な権限を与えられた登録ゲーム機の使用者の管理上の過失を含む故意または重大な過失により発生したものであるとき
    ・本サービス対象製品の設計上の問題に起因する不具合
    ２  前条にかかわらず、次に定める場合には、加入者は保証を受けることができないことがあります。
    ・任天堂販売または任天堂に対して、本サービス利用料金未払いを含む、何らかの料金未払いがある場合
    ・保証のお申込が第20条に定める禁止事項に該当するとき
    ・過去に本規約への違反があり、保証のお申込時においてなお当該違反が是正されていないとき
    \n第15条（保証のお申込方法）
    保証のお申込は、保証対象事故の発生日から起算して30日以内に、任天堂販売が別途本サービスサイトに定める方法に従って行います。なお、保証のお申込受付にあたり、任天堂販売は加入者本人からのお申込であることを確認するものとします。
    ２  前項にかかわらず、保証のお申込内容、加入者の本サービスのご利用状況等によっては、保証のお申込に際し、加入者の費用負担により任天堂販売が保証のお申込方法を指定する場合があります。
    ３  任天堂販売は、保証の申込受付時に必要と判断した場合は、本条に定める方法以外に、各種確認書類（ご購入時の領収書、本人確認書類等）の写しの提出を加入者に求める場合があります。
    ４  保証のお申込がなされ、任天堂販売からの配送用部材の発送通知があった日の翌日から起算して2か月以内に任天堂販売へ登録ゲーム機が届かなかった場合（任天堂販売に責任がある場合を除き、その理由を問いません）、当該保証のお申込はキャンセルとなります。この場合であっても、第12条3項に従い、第12条1項で定める保証の回数に算入されます。ただし、本サービスは2024年8月31日をもって修理受付を終了し、2024年9月7日をもってお客様からの登録ゲーム機の受け取りを終了します。2024年9月8日以降に任天堂販売へ登録ゲーム機が届いたとしても本サービスの対象とはなりません。
    \n第16条（保証）
    任天堂販売は、前条に基づき加入者から保証のお申込を受けた場合、ご申告内容を確認し、保証の対象となると判断した場合に、保証を提供します。
    ２  保証を提供するにあたり必要に応じて申し込まれた登録ゲーム機1台につき別途任天堂販売が指定する機種またはカラーに交換を行うことがございます。加入者は、これにより登録ゲーム機の全部または一部が変更になる場合があることをあらかじめ了承します。
    ３  任天堂販売は、前項に基づき登録ゲーム機の全部または一部を変更する場合、都度加入者の承諾を得ることを要しないものとします。
    \n第17条（受け取りについて)
    任天堂販売が登録ゲーム機の一部または全部を加入者へ返送した場合、加入者は理由の如何を問わず受け取りを拒否する事はできないものとします。
    ２  加入者が登録ゲーム機の受け取りを拒否した場合、または転居等により受け取りができずかつ任天堂販売が加入者と14日以上連絡が取れない場合は、加入者が保証のお申込みをした日から12か月を経過した時点で、当該登録ゲーム機の所有権は任天堂販売へ移譲されます。
    ３  前項にかかわらず、本サービスの受付が終了する2024年8月31日から6か月を経過した時点で加入者が登録ゲーム機の受け取りを完了していない場合は、当該登録ゲーム機の所有権は任天堂販売へ移譲されます。
    ４  前二項に基づき当該登録ゲーム機の所有権が移譲された後、任天堂販売は登録ゲーム機の一部または全部を廃棄処分することとし、加入者はあらかじめこれを了承します。
    ５  第2項および第3項の規定により任天堂販売が登録ゲーム機を廃棄処分した場合でも、利用者は本サービスの利用料金を免れることはできず、また、既に支払済みの利用料金の返金を求めることはできません。
    \n第18条（修理対応の諸条件）
    任天堂販売が保証の提供をするに伴い、修理の方法や使用する修理用部品はすべて任天堂が指定する仕様とし、任天堂が定める基準を上回る修理対応はいたしかねます。
    ２  修理対応時の修理用部品の在庫状況に応じて、修理対応をしかねる場合がございます。
    ３  修理対応の都合により、他のサービスとの連携の解除、各種設定の初期化や内部データの消失等の可能性があります。その場合でも、任天堂および任天堂販売は一切の責任を負いません。
    ４  前三項に掲げるほか、修理対応の諸条件については、本規約に適合する限り、任天堂が提供するNintendo Switch サポートの修理サービス規程および保証規程に規定の条件に従います。
    \n第19条（確認書等の返送）
    加入者は、保証のお申込に際し、任天堂販売が指定する方法で、本規約および修理同意書等に同意の上、任天堂販売に申込をするものとします。
    \n第20条（禁止事項）
    加入者は、本サービスのご利用にあたり自らまたは第三者をして以下の行為を行わないものとします。
    ・本サービスの利用申込時、保証のお申込時、その他本サービスのご利用にあたり、虚偽の登録、届出または申告を行う行為
    ・他者になりすましての本サービスの利用や、他の本サービス利用者の利用を妨害するなどの不正な行為
    ・お客様のご要望を実現するための手段として社会通念上相当な範囲を超える行為（下記のとおりですが、これに限りません） 
       -威迫/脅迫/威嚇行為 
       -侮辱、人格を否定する発言 
       -プライバシー侵害行為 
       -保証の範囲を超えた無償修理の要求など、社会通念上過剰なサービス提供の要求 
       -合理的理由のない任天堂販売への謝罪要求や任天堂販売関係者への処罰の要求 
       -同じ要望やクレームの過剰な繰り返し等による長時間の拘束行為  
       -SNSやインターネット上での誹謗中傷
    ・本サービス対象製品を販売または転売をする目的その他業に用いる目的で本サービスの利用申込、保証のお申込または本サービスの利用をする行為
    ・サービス利用契約により生じた権利、義務または契約上の地位を、任天堂販売の承諾なく第三者に譲渡もしくは承継する行為
    ・任天堂販売、任天堂、第三者もしくはそれらの従業員、関係者の知的財産権、所有権、その他の権利を侵害する行為、またはそのおそれのある行為
    ・本サービスの提供に関する任天堂販売、任天堂もしくは第三者の設備に無権限でアクセスし、過度な負担を与え、その他本サービスの提供およびその運営に支障を与えるなど、営業活動を妨害する行為、またはそのおそれのある行為
    ・本サービスを解約し、6か月以内に同一のアカウント（登録される加入者情報が同一のアカウントを指します）からの本サービスへの加入および保証のお申込（別の本サービスアカウントから同一の住所による本サービスへの加入および保証のお申込がされた場合を含みます。）
    ・犯罪行為もしくは犯罪に結びつく行為、またはそのおそれのある行為
    ・前各号の他、法令、公序良俗、本規約もしくは本サービスに関連する規約等に違反する行為、またはそのおそれのある行為
    ・その他任天堂販売が不適切と判断する行為
    \n第21条（個人情報の取り扱い）
    本サービスに関するお客様の情報は、本サービスのプライバシーポリシー（https://widecare-switch.com/privacy）に基づきエーオンジャパンが取得、管理、保管するものとし、同プライバシーポリシー3.2に掲げる場合を除き、任天堂販売は、お客様の情報につき一切取り扱うものではありません。
    \n第22条（加入者からの不更新）
    加入者が本サービスの不更新を希望するときは、任天堂販売が別途本サービスサイトに定める方法に従い任天堂販売に対して本サービスの不更新を申し出るものとします。
    ２  前項に従い加入者が本サービスの不更新を申し出た場合でも、任天堂販売は当該加入者から領収済みのサービス利用料金を返金しません。
    \n第23条（解除）
    任天堂販売は、加入者が以下のいずれかに該当した場合、なんら通知または催告をすることなく加入者と任天堂販売との間のサービス利用契約を解除することができるものとします。
    ・第9条（支払い）各項に定める本サービス利用料金またはその他本サービスにより生じた債務を、履行期限を経過しても履行いただけない場合
    ・第20条（禁止事項）に定める禁止行為に該当する行為その他本規約のいずれかに違反した場合
    ・本規約に基づく変更の届出を怠る等の事由により、加入者のご連絡先が不明となり、任天堂販売から加入者に対するご連絡が不能になったと任天堂販売が判断した場合
    ・その他加入者の本サービスのご利用状況が不適当であると任天堂販売が判断した場合
    \n第24条（サービス利用契約の終了）
    加入者が以下に定める事項のいずれかに該当した場合、当該時点をもって、加入者と任天堂販売との間のサービス利用契約は終了し、任天堂販売は加入者への本サービスの提供を終了します。
    ・第22条（加入者からの不更新）1項に基づき任天堂販売が加入者からの本サービスの不更新申出を受領した場合
    ・前条に基づき任天堂販売が加入者との間のサービス利用契約を解除した場合
    \n第25条（本サービスの停止）
    任天堂販売は、加入者に事前に通知または周知することにより、本サービスの提供を一時的に停止することができるものとします。
    ２  任天堂販売は、本サービスの提供に関するシステム上のまたはシステムを作動させる機器その他の故障または停止、本サービスの提供に利用する建物、装置その他の設備の保守、更新または工事等による利用の停止、エーオンジャパンその他の本サービス運営の委託先のサービス提供の停止または終了、もしくは天災地変その他やむを得ない事由により、加入者に事前に通知または周知することなく、一時的に本サービスの提供を停止する場合があります。
    \n第26条（本サービスの終了）
    任天堂販売は、加入者に事前に通知または周知することにより、本サービスの全部または一部の提供を終了することができるものとします。なお、本サービスが終了した場合は、当該時点をもってサービス利用契約は自動的に終了するものとします。
    \n第27条（本規約の変更）
    任天堂販売は、次の各号に掲げるいずれかの事由に該当するときは、あらかじめ任天堂販売が適当と判断する方法により加入者に通知し、または本サービスサイトもしくは本サービス加入のためのウェブサイト上で周知することにより、本サービスまたは本規約の内容の一部もしくは全部を変更できるものとし、この場合、変更日以降は変更後の本規約が適用されるものとします。
    ①本規約の変更が、加入者の一般の利益に適合するとき。
    ②本規約の変更が、サービス利用契約をした目的に反せず、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。
    \n第28条（責任制限事項）
    任天堂販売の責めに帰すべき事由により、本サービスの提供の遅延、変更、中断、停止もしくは終了、その他本サービスの利用に関連してまたは本サービスを利用できないことによって、加入者に損害が生じた場合、任天堂販売は、故意または重大な過失がある場合を除き、通常かつ直接の損害に限りその損害を賠償するものとし、間接損害、特別損害、付随的損害、派生的損害、逸失利益、使用機会の損失による損害についての責任は負わないものとします。 
    ２  本サービスに関連して任天堂販売が負担する損害賠償額の限度額は、任天堂販売に故意または重大な過失がある場合を除き、債務不履行、法律上の瑕疵担保責任、不当利得、不法行為、その他請求原因の如何にかわらず、損害が発生した月を含む過去12か月における各月の本サービスの月額料金の合計額を上限とします。
    \n第29条（完全合意）
    本規約は、加入者が任天堂販売との間でサービス利用契約を締結された日における加入者と任天堂販売との合意を規定したものであり、サービス利用契約締結以前に加入者と任天堂販売との間でなされた合意事項等と本規約の内容とが相違する場合は、本規約が優先するものとします。
    \n第30条（窓口）
    本サービスの内容に関するご質問、その他ご利用に関する問い合わせ等については、別途本サービスサイトに定める連絡先を窓口とします。
    \n第31条（合意管轄）
    加入者と、任天堂販売との間でサービス利用契約に関連して訴訟の必要が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
    \n以上
    （2022年7月1日制定）
    （2023年8月31日改定）
  `

  onCancel () {
    this.$modal.hide(this.name)
  }

  async onConfirm () {
    this.$modal.hide(this.name)
  }

  private restorePoint = 0

  beforeOpen() {
    this.restorePoint = document?.documentElement?.scrollTop || 0
    modalBeforeOpenHandling()
  }

  beforeClose() {
    modalBeforeCloseHandling(this.restorePoint)
  }
}
