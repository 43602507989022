import { Shift } from '@/services/Shift'
import { UserState } from '@/store/api/UserState'
import { RootState } from '@/store/api/RootState'
import { ActionContext, ActionTree } from 'vuex'
import { SET_EMAIL, SET_NEW_EMAIL, SET_POLICY_HOLDER, SET_USER_STATE } from './mutationTypes'
import { ACTION_RESET_CLAIM_STATE } from '../claim/actionTypes'
import { ACTION_RESET_POLICY_STATE } from '../policy/actionTypes'
import { ACTION_RESET_PRODUCT_STATE } from '../product/actionTypes'
import { initialState } from '../user/initialState'
import { Auth } from '@/services/Auth'
import { PolicyPartyModel } from 'shift-policy-service-api-client'

type UserContext = ActionContext<UserState, RootState>

export const actions: ActionTree<UserState, RootState> = {
  logout({ dispatch, commit }: UserContext): void {
    dispatch(ACTION_RESET_CLAIM_STATE, null, { root: true })
    dispatch(ACTION_RESET_POLICY_STATE, null, { root: true })
    dispatch(ACTION_RESET_PRODUCT_STATE, null, { root: true })
    commit(SET_USER_STATE, { ...initialState })
    Auth.logout()
  },
  setEmail({ commit }: UserContext, email: string): void {
    commit(SET_EMAIL, email)
  },
  setNewEmail({ commit }: UserContext, newEmail: string): void {
    commit(SET_NEW_EMAIL, newEmail)
  },
  async setPolicyHolder({ commit }: UserContext): Promise<void> {
    const policyHolder: PolicyPartyModel | null = await Shift.getPolicyHolderDetail()
    if (policyHolder) {
      commit(SET_POLICY_HOLDER, policyHolder)
    }
  }
}
