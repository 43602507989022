import {
  Auth,
  WindowSessionStorage,
  GuestAuthStrategy,
  GuestAuthStrategyConfig,
  DefaultTokenProvider,
  axiosFactory,
  HotpAuthStrategyConfig,
  HotpAuthStrategy,
  TokenProvider
} from '@uncharted/coverhub-framework'
import { Config } from '@/config'
import axios from 'axios'

const publicPath = Config.publicPath ? `${Config.publicPath}` : '/'

let _cachedClientIds: any = {}

async function clientIdProvider(strategy: string): Promise<string> {
  if (process.env.VUE_APP_SHIFT_CLIENT_ID) {
    return process.env.VUE_APP_SHIFT_CLIENT_ID
  } else {
    if (_cachedClientIds[strategy]) {
      return _cachedClientIds[strategy]
    } else {
      const resp = await axios.get<any>(`${publicPath}client-ids.json`)
      const hostName = window.location.hostname
      _cachedClientIds = resp.data[hostName]
      return _cachedClientIds[strategy]
    }
  }
}

const conf = {
  callback: `${window.origin}${publicPath}callback/index.html`,
  iframe: false,
  failureRedirect: '/error',
  clientIdProvider
}

const guestConfig: GuestAuthStrategyConfig = {
  ...conf,
  url: Config.shift.guestAuthUrl
}

const hotpConfig: HotpAuthStrategyConfig = {
  ...conf,
  url: Config.shift.otpAuthUrl,
  loginUrl: '/signin',
  emailSuccessRedirect: 'email',
  otpSuccessRedirect: 'otp'
}

const auth = new Auth('/error', WindowSessionStorage)
const guestAuthStrategy = new GuestAuthStrategy(guestConfig)
const hotpAuthStrategy = new HotpAuthStrategy(hotpConfig)
const guestTokenProvider = new DefaultTokenProvider(
  guestAuthStrategy,
  WindowSessionStorage
)
const otpTokenProvider = new DefaultTokenProvider(
  hotpAuthStrategy,
  WindowSessionStorage
)
auth.use(guestAuthStrategy)
auth.use(hotpAuthStrategy)

const aonTokenProvider: TokenProvider = {
  getToken(): Promise<string | null> {
    if (auth.loggedInAs('otp')) {
      return otpTokenProvider.getToken()
    } else {
      return guestTokenProvider.getToken()
    }
  },
  refreshToken(): Promise<void> {
    if (auth.loggedInAs('otp')) {
      auth.logout()
      return Promise.resolve()
    } else {
      return guestTokenProvider.refreshToken()
    }
  },
  clearToken(): void {
    guestTokenProvider.clearToken()
    otpTokenProvider.clearToken()
  }
}

const keepOnErrorTokenProvider: TokenProvider = {
  ...aonTokenProvider,
  clearToken(): void {
    // not clearing token for api errors
  }
}

const shiftAxios = axiosFactory(aonTokenProvider)
const keepOnErrorAxios = axiosFactory(keepOnErrorTokenProvider)
const otpAxios = axiosFactory(aonTokenProvider)

export { auth as Auth, shiftAxios as xhr, otpAxios as otpXhr, keepOnErrorAxios }
