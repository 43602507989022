import {
  ClaimOverlay,
  Product,
  ClaimConfig,
  FactIdFilter,
  Claim,
  OrFilter,
  op,
  DefaultClaimOverlay,
  PostcodeJpInterceptor
} from '@uncharted/coverhub-framework'
import { Config } from '@/config'
import { Shift } from './Shift'
import { Policies } from './api/Policies'
import { Claims } from './api/Claims'
import { SwitchPolicyModel } from './api/models/SwitchPolicyModel'
import { createVirtualFact } from './api/models/RepairModel'
import constants from '@/constants'
import { PostcodeApiKeyService } from '@/services/PostcodeApiKeyService'
import { ClaimDefinitionModel } from 'shift-claim-service-api-client'
import { ProductModel } from 'shift-product-service-api-client'
import { PolicyModel } from 'shift-policy-service-api-client'

export const ClaimsService = {
  /**
   * Returns a claim, properly initialized
   * @param claimId Claim id
   * @param claimDefinition
   * @param productModel
   * @param policyModel
   */
  async getClaimById(claimId: string, claimDefinition: ClaimDefinitionModel, productModel: ProductModel, policyModel: PolicyModel): Promise<Claim> {
    // Load the claim
    const claimModel = (await Claims.api.getClaim(claimId)).data.data
    // Create a product with product engine
    const product = new Product({
      definition: productModel.definition,
      serialized: policyModel.config,
      language: Config.locale
    })
    // Define a filter for filtering accepted claims
    // for the given policy, excluding this claim
    const claimFilter = `claimDefinitionId[eq]=${claimDefinition.id}|id[!eq]=${claimId}|policyId[eq]=${claimModel.policyId}|status[eq]=ACCEPTED`
    // Load accepted claims during coverage period
    const prevClaimModels = (
      await Claims.api.getAllClaims(
        undefined,
        undefined,
        undefined,
        claimFilter
      )
    ).data.data
    // Extract claim config from previous claims
    const prevClaimConfigs = prevClaimModels.map((cm) => cm.config)
    // Define config for a claim, that wraps all this stuff
    const claimConfig: ClaimConfig = {
      language: Config.locale,
      definition: claimDefinition.definition,
      product: product,
      previousClaims: prevClaimConfigs,
      serialized: claimModel.config,
      meta: {
        claimDefinitionId: claimDefinition.id,
        policyId: policyModel.id
      }
    }
    // Now, create the new empty claim and return it
    return new Claim(claimModel.claimNumber, claimConfig)
  },
  /**
   * Create an empty claim for given policy, properly initialized, whith
   * an optional serialized config, in case web browser was reloaded.
   */
  async createEmptyClaim(
    policyId: string,
    serializedConfig?: string
  ): Promise<Claim> {
    // Load the policy for product config
    const policyModel = (await Policies.api.getPolicy(policyId)).data.data
    // Load product model for accessing product definition
    const productModel = await Shift.getProductById(policyModel.productId)
    // Create a product with product engine
    const product = new Product({
      definition: productModel.definition,
      serialized: policyModel.config,
      language: Config.locale
    })
    // Load claim definition for the specified product
    const claimDefinition = await Shift.getClaimDefinition(productModel.id)
    // Define a filter for filtering accepted claims for the given policy
    const claimFilter = `claimDefinitionId[eq]=${claimDefinition.id}|policyId[eq]=${policyId}|status[eq]=ACCEPTED`
    // Load accepted claims during coverage period
    const prevClaimModels = (
      await Claims.api.getAllClaims(
        undefined,
        undefined,
        undefined,
        claimFilter
      )
    ).data.data
    // Extract claim config from previous claims
    const prevClaimConfigs = prevClaimModels.map((cm: any) => cm.config)
    // Define config for a claim, that wraps all this stuff
    const claimConfig: ClaimConfig = {
      language: Config.locale,
      definition: claimDefinition.definition,
      product: product,
      previousClaims: prevClaimConfigs,
      serialized: serializedConfig,
      meta: {
        claimDefinitionId: claimDefinition.id,
        policyId: policyModel.id
      }
    }
    // Now, create the new empty claim and return it
    return new Claim('', claimConfig)
  },
  /**
   * Creates an empty claim, ready for population
   */
  async createClaimOverlays(
    policyId: string,
    _productId: string,
    _product: Product,
    consent: string,
    serializedConfig: string | null,
    switchPolicyModel: SwitchPolicyModel
  ): Promise<ClaimOverlay[]> {
    const claim = await this.createEmptyClaim(
      policyId,
      serializedConfig || undefined
    )
    function serialFilters(): FactIdFilter[] {
      const filter: FactIdFilter[] = [
        new FactIdFilter('device.serialbody', op.eq)
      ]
      if (switchPolicyModel.deviceType === constants.deviceVariants.SWITCH || switchPolicyModel.deviceType === constants.deviceVariants.SWITCH_OLED) {
        filter.push(
          new FactIdFilter('device.serialjoyconleft', op.eq),
          new FactIdFilter('device.serialjoyconright', op.eq)
        )
      }
      return filter
    }
    claim.claimEngine.setFactValue('claim.repairconsent', consent)
    const repairFilter = new OrFilter([
      new FactIdFilter('device.type', op.eq),
      new FactIdFilter('device.nickname', op.eq),
      ...serialFilters(),
      new FactIdFilter('virtual.', op.startsWith),
      // new FactIdFilter('claim.type', op.eq),
      new FactIdFilter('claim.issue', op.eq),
      new FactIdFilter('claim.issue.other', op.eq),
      new FactIdFilter('claim.dateofevent', op.eq)
    ])
    const shippingFilter = new OrFilter([
      new FactIdFilter('claimant', op.startsWith)
    ])

    const virtualFacts = [
      createVirtualFact(
        'virtual.repairTimesLastOneYear',
        switchPolicyModel.repairTimesLastOneYear
      ),
      createVirtualFact(
        'virtual.amortizeRepairCost',
        switchPolicyModel.amortizeRepairCost
      )
    ]

    const repairOrder = [
      'device.type',
      'device.nickname',
      'device.serialbody',
      'device.serialjoyconleft',
      'device.serialjoyconright',
      'virtual.repairTimesLastOneYear',
      'virtual.amortizeRepairCost',
      'claim.issue',
      'claim.issue.other',
      'claim.dateofevent'
    ]
    function repairSort(a: any, b: any) {
      const aIx = repairOrder.indexOf(a.id)
      const bIx = repairOrder.indexOf(b.id)
      return aIx - bIx
    }

    const repairOverlay = new DefaultClaimOverlay(
      'repair',
      claim,
      repairFilter,
      [],
      virtualFacts,
      repairSort
    )

    const apiKey: string = await PostcodeApiKeyService.getApiKey()

    const postCodeInterceptor = new PostcodeJpInterceptor({
      apiKey: apiKey,
      glyph: 'kanji',
      targetFactId: 'claimant.postalcode',
      boundFacts: [
        {
          factId: 'claimant.prefecture',
          field: 'administrativeArea'
        },
        {
          factId: 'claimant.city',
          field: 'locality'
        },
        {
          factId: 'claimant.town',
          field: 'district'
        }
      ]
    })
    const order = [
      'claimant.lastnamekanji',
      'claimant.firstnamekanji',
      'claimant.lastnamekana',
      'claimant.firstnamekana',
      'claimant.phonenumber',
      'claimant.postalcode',
      'claimant.prefecture',
      'claimant.city',
      'claimant.town',
      'claimant.buildingnameandroomnumber'
    ]
    function sort(a: any, b: any) {
      const aIx = order.indexOf(a.id)
      const bIx = order.indexOf(b.id)
      return aIx - bIx
    }
    const shippingOverlay = new DefaultClaimOverlay(
      'shipping',
      claim,
      shippingFilter,
      [postCodeInterceptor],
      undefined,
      sort
    )
    return [repairOverlay, shippingOverlay]
  }
}
