import { PolicyState } from '@/store/api/PolicyState'
import { RootState } from '@/store/api/RootState'
import { ActionContext, ActionTree } from 'vuex'
import { Shift } from '@/services/Shift'
import {
  SET_POLICIES,
  SET_POLICY_CONVERTED_POLICY,
  SET_POLICY_CANCEL_PLAN,
  SET_POLICY_CERTIFICATE,
  SET_POLICY_PHOTOS,
  SET_POLICY_STATE
} from './mutationTypes'
import { initialState } from './initialState'
import { CancelPlanModel } from '@/store/api/models/CancelPlanModel'
import { PhotoModel } from '@/store/api/models/PhotoModel'
import { Cms } from '@/services/api/Cms'
import { PolicyCertificateModel } from '@/store/api/models/PolicyCertificateModel'
import { doutformat } from '@/services/functions'
import { PolicyModel } from 'shift-policy-service-api-client'

type PolicyContext = ActionContext<PolicyState, RootState>

export const actions: ActionTree<PolicyState, RootState> = {
  async loadPolicies(
    { commit }: PolicyContext,
    onlyInForcePolicies = false
  ): Promise<void> {
    const policies = (await Shift.getPolicies(onlyInForcePolicies))
    commit(SET_POLICIES, policies)
  },
  /**
   * Load switch images
   */
  async loadImageUrls(
    { commit, state }: PolicyContext,
    policyId: string
  ): Promise<void> {
    const model = state.policies.find((p) => p.policyId === policyId)
    if (model) {
      const res = await Promise.all([
        Cms.api.getContentObject(model.photoFront),
        Cms.api.getObjectContent(model.photoFront, { responseType: 'blob' }),
        Cms.api.getContentObject(model.photoBack),
        Cms.api.getObjectContent(model.photoBack, { responseType: 'blob' })
      ])
      const frontBlob = new Blob([res[1].data], { type: res[0].data.data.contentType })
      const backBlob = new Blob([res[3].data], { type: res[2].data.data.contentType })
      const photoModel: PhotoModel = {
        front: {
          contentType: res[0].data.data.contentType,
          name: res[0].data.data.fileName,
          base64: URL.createObjectURL(frontBlob),
          blob: frontBlob
        },
        back: {
          contentType: res[2].data.data.contentType,
          name: res[2].data.data.fileName,
          base64: URL.createObjectURL(backBlob),
          blob: backBlob
        }
      }
      commit(SET_POLICY_PHOTOS, photoModel)
    }
  },
  resetState({ commit }: PolicyContext): void {
    commit(SET_POLICY_STATE, { ...initialState })
  },
  async loadCancelPlan(
    { commit, state }: PolicyContext,
    policyId: string
  ): Promise<void> {
    // Locate policy user wants to cancel
    const switchPolicyModel = state.policies.find(
      (p) => p.policyId === policyId
    )
    if (switchPolicyModel) {
      const session = switchPolicyModel._product.productEngine
      const deviceNickname = session.getFactValue(
        'COMPONENT:base.deviceregistration.nickname'
      )
      const deviceType = session.getFactValue(
        'COMPONENT:base.coverage.devicetype'
      )
      const serialNumber = session.getFactValue(
        'COMPONENT:base.deviceregistration.photofront.serialbody'
      )
      const cancellationDate = session.describePolicy().policyEndDate
      const originalStartDate = switchPolicyModel.originalStartDateTime
      const consent = false
      const cancelPlanModel: CancelPlanModel = {
        deviceNickname,
        deviceType,
        serialNumber,
        originalStartDate,
        cancellationDate,
        consent,
        policyId
      }
      commit(SET_POLICY_CANCEL_PLAN, cancelPlanModel)
    }
  },
  async cancelPlan({ state }: PolicyContext): Promise<void> {
    if (!state.cancelPlan) {
      throw new Error('cancelPlan state is null')
    }
    await Shift.cancelSubscription(
      state.cancelPlan.policyId,
      state.cancelPlan.consent
    )
  },
  async loadPolicyCertificate(
    { commit, state }: PolicyContext,
    policyId: string
  ): Promise<void> {
    // Locate policy user wants to see
    const switchPolicyModel = state.policies.find(
      (p) => p.policyId === policyId
    )
    if (switchPolicyModel) {
      const model: PolicyCertificateModel = {
        deviceType: switchPolicyModel.deviceType,
        policyNumber: switchPolicyModel._policy.policyNumber,
        serialNumber: switchPolicyModel.serialNumber,
        productionNumberJoyConLeft:
          switchPolicyModel.deviceType === 'switchlite'
            ? ''
            : switchPolicyModel.productionNumberJoyConLeft,
        productionNumberJoyConRight:
          switchPolicyModel.deviceType === 'switchlite'
            ? ''
            : switchPolicyModel.productionNumberJoyConRight,
        planType: switchPolicyModel.planType,
        dateOfSubscription:
          doutformat(switchPolicyModel._policy.sfCustom.dateOfSubscription as unknown as string),
        warrantyStartDate:
          doutformat(switchPolicyModel.coverageStartDate),
        warrantyEndDate:
          doutformat(switchPolicyModel.coverageEndDate),
        originalStartDateTime:
          doutformat(switchPolicyModel._policy.originalStartDateTime)
      }
      commit(SET_POLICY_CERTIFICATE, model)
    } else {
      throw new Error(`policy for ${policyId} not found in store`)
    }
  },
  /**
   * Temporarily store converted info
   */
  setConvertedPolicy({ commit }: PolicyContext, policy: PolicyModel): void {
    commit(SET_POLICY_CONVERTED_POLICY, policy)
  },
  setLanguage({ state }: PolicyContext, language: string) {
    state.policies.forEach(p => p._product.productEngine.setLanguage(language))
  }
}
