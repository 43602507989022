import { otpXhr } from '../Auth'
import { Config } from '@/config'
import {
  V3PolicyApi,
  V3PolicyApiInterface
} from 'shift-policy-service-api-client'
import { ApiFactory } from '@uncharted/coverhub-framework'

const policies = ApiFactory<V3PolicyApiInterface, V3PolicyApi>(
  V3PolicyApi,
  Config.shift.url,
  otpXhr
)

export { policies as Policies }
