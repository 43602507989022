import { Config } from '@/config'
import { Claim, DefaultClaimOverlay, FactIdFilter, op, OrFilter, shift } from '@uncharted/coverhub-framework'
import FactDescription = shift.claimengine.description.fact.FactDescription;
import FactType = shift.claimengine.definition.FactType;
import UsedBy = shift.claimengine.definition.UsedBy;
import FactOptionDescription = shift.claimengine.description.fact.FactOptionDescription;

export interface ClaimRepairModel {
  claim: Claim;
  facts: FactDescription[];
  status: string;
}

export interface RepairModel {
  device: {
    type: string;
    nickname: string;
  };
  policyId: string;
  claims: ClaimRepairModel[];
  policyCoverageStartDate: string;
  policyCoverageEndDate: string;
}

export interface ClaimCustomFields {
  repairStatus?: string;
  deliveryTrackingNumber?: string;
  returnTrackingNumber?: string;
  shippingCompany?: string;
  repairDate?: string;
  repairComments?: string;
}

const filter: OrFilter = new OrFilter(
  [
    'device.type',
    'device.nickname',
    'device.serialbody',
    'device.serialjoyconleft',
    'device.serialjoyconright',
    'claim.issue',
    'claim.issue.other',
    'virtual.repairDate',
    'virtual.claimNumber',
    'outcome.customRepairStatus',
    'virtual.name',
    'claimant.phonenumber',
    'virtual.address',
    'outcome.type',
    'outcome.shippingDate',
    'virtual.deliveryTrackingNumber',
    'outcome.shippingDateOfFinishedGoods',
    'virtual.returnTrackingNumber',
    'outcome.arrivalDateOfCompletedGoods',
    'outcome.cost.totalCost',
    'virtual.shippingCompany',
    'virtual.repairComments'
  ].map(s => new FactIdFilter(s, op.eq))
)

export function createVirtualFact(
  id: string,
  value: string,
  options: FactOptionDescription[] = []
): FactDescription {
  return new FactDescription(
    id, id, id, FactType.TEXT,
    false, '', options, value, '', false, true, [UsedBy.CLAIM], value, '', [], []
  )
}

function getVirtualFacts(claim: Claim, customFields: ClaimCustomFields): shift.claimengine.description.fact.FactDescription[] {
  customFields.shippingCompany = customFields.shippingCompany || Config.customFields.shippingCompany

  const virtualFacts: FactDescription[] = []
  virtualFacts.push(
    createVirtualFact(
      'virtual.claimNumber',
      claim.claimNumber
    ),
    createVirtualFact(
      'virtual.repairDate',
      claim.claimEngine.getFactValue('#claimStart')
    ),
    createVirtualFact(
      'virtual.returnTrackingNumber',
      customFields.returnTrackingNumber || ''
    ),
    createVirtualFact(
      'virtual.deliveryTrackingNumber',
      customFields.deliveryTrackingNumber || ''
    ),
    createVirtualFact(
      'virtual.shippingCompany',
      customFields.shippingCompany
    ),
    createVirtualFact(
      'virtual.repairComments',
      customFields.repairComments || ''
    ),
    createVirtualFact(
      'virtual.name',
      `${claim.claimEngine.getFactValue('claimant.lastnamekanji')} ${claim.claimEngine.getFactValue('claimant.firstnamekanji')}`
    ),
    createVirtualFact(
      'virtual.address',
      `${claim.claimEngine.getFactValue('claimant.postalcode')} ${claim.claimEngine.getFactValue('claimant.prefecture')} ${claim.claimEngine.getFactValue('claimant.city')} ${claim.claimEngine.getFactValue('claimant.town')} ${claim.claimEngine.getFactValue('claimant.buildingnameandroomnumber') || ''}`
    )
  )
  return virtualFacts
}
const order = [
  'device.type',
  'device.nickname',
  'device.serialbody',
  'device.serialjoyconleft',
  'device.serialjoyconright',
  'claim.issue',
  'claim.issue.other',
  'virtual.repairDate',
  'virtual.claimNumber',
  'outcome.customRepairStatus',
  'virtual.name',
  'claimant.phonenumber',
  'virtual.address',
  'outcome.shippingDate',
  'virtual.deliveryTrackingNumber',
  'outcome.shippingDateOfFinishedGoods',
  'virtual.returnTrackingNumber',
  'outcome.arrivalDateOfCompletedGoods',
  'outcome.cost.totalCost',
  'virtual.shippingCompany',
  'virtual.repairComments'
]
function sort(a: any, b: any) {
  const aIx = order.indexOf(a.id)
  const bIx = order.indexOf(b.id)
  return aIx - bIx
}

export function createClaimOverlay(claim: Claim, customFields: any) {
  const virtualFacts = getVirtualFacts(claim, customFields as ClaimCustomFields)
  return new DefaultClaimOverlay('repair', claim, filter, [], virtualFacts, sort)
}
