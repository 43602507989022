import { otpXhr } from '../Auth'
import { Config } from '@/config'
import { ApiFactory } from '@uncharted/coverhub-framework'
import { V1WorkflowApi, V1WorkflowApiInterface } from 'shift-workflow-service-api-client'

const workflows = ApiFactory<V1WorkflowApiInterface, V1WorkflowApi>(
  V1WorkflowApi,
  Config.shift.url,
  otpXhr
)

export { workflows as Workflows }
