import modalNames from './modalName'
import routeNames from './routeNames'
import transitionNames from './transitionNames'
import deviceVariants from './deviceVariants'
import eventNames from './eventNames'
import formats from './formats'

export default {
  modalNames,
  routeNames,
  deviceVariants,
  transitionNames,
  eventNames,
  formats
}
