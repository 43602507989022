







































































import { Component, Vue } from 'vue-property-decorator'
import Headers from '@/components/Header.vue'
import TerminationNotice from './TerminationNotice.vue'
import TermAndConditionUpdateNotice from './TermAndConditionUpdateNotice.vue'
import { Auth } from '@/services/Auth'
import { EventBus, AUTH_EVENT_NAMES, AuthEvent } from '@uncharted/coverhub-framework'
import constants from '@/constants'
import { Getter } from 'vuex-class'

@Component({
  name: 'Home',
  components: {
    Headers,
    TerminationNotice,
    TermAndConditionUpdateNotice
  }
})
export default class Home extends Vue {
  @Getter('app/suspendRegistration')
  suspendRegistration!: boolean

  loggedIn = Auth.loggedInAs('otp')

  private clicked = false

  created() {
    this.onAuthEvent = this.onAuthEvent.bind(this)
    EventBus.on(AUTH_EVENT_NAMES.AUTH_EVENT, this.onAuthEvent)
  }

  destroyed() {
    EventBus.off(AUTH_EVENT_NAMES.AUTH_EVENT, this.onAuthEvent)
  }

  onAuthEvent(event: AuthEvent) {
    if (event.strategy === 'otp') {
      this.loggedIn = event.name === 'login'
    }
  }

  goToApplication() {
    this.clicked = true
    this.$router.push({
      name: this.ROUTE_NAMES.BEFORE_APPLICATION
    })
  }

  goToServiceIntroduction() {
    this.$modal.show(constants.modalNames.SERVICE_INTRODUCTION_MODAL)
  }
}
