import { AppState } from '@/store/api/AppState'

export const initialState: AppState = {
  version: '0',
  newVersion: '0',
  affId: '',
  showTermination: false,
  showTermination2: false,
  showTermAndCondition: false,
  disablePlanChange: false,
  suspendRegistration: false,
  deactivateAddNewDevice: false,
  deactivateUpdatePayment: false,
  deactivateCancelCoverage: false,
  extraWaitingMessage: false,
  servicNotice2: false,
  newTermUse: false
}
