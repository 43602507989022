import { ClaimState } from '@/store/api/ClaimState'
import { RootState } from '@/store/api/RootState'
import { GetterTree } from 'vuex'
import { ClaimOverlay } from '@uncharted/coverhub-framework'
import { SwitchPolicyModel } from '@/services/api/models/SwitchPolicyModel'
import { FactDescription, ValidationError } from '@/interfaces/engine-exports'
import { SubmittedClaim } from '@/store/api/models/SubmittedClaim'
import { RepairModel } from '@/services/api/models/RepairModel'

export interface FnGetOverlayByName {
  (name: string): ClaimOverlay | null;
}

export interface FnGetRepairByPolicyId {
  (id: string): RepairModel | undefined;
}

export const getters: GetterTree<ClaimState, RootState> = {
  overlays(state: ClaimState): ClaimOverlay[] {
    return state.overlays
  },
  validationErrors(state: ClaimState): ValidationError[] {
    return state.validationErrors
  },
  getOverlayByName: (state: ClaimState) => (
    name: string
  ): ClaimOverlay | null => {
    return state.overlays?.find((o) => o.name === name) || null
  },
  repairPolicyModel(state: ClaimState): SwitchPolicyModel | null {
    return state.repairPolicyModel
  },
  submittedClaim(state: ClaimState): SubmittedClaim {
    return state.submittedClaim
  },
  repairs(state: ClaimState): RepairModel[] {
    return state.repairs
  },
  getRepairByPolicyId: (state: ClaimState) => (
    id: string
  ): RepairModel | undefined => {
    return state.repairs.find(r => r.policyId === id)
  },
  facts(state: ClaimState): FactDescription[] {
    return state.facts
  }
}
