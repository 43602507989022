


















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Accordion extends Vue {
  @Prop() title?: string;
  @Prop() content?: string;

  isOpen = false;

  togglePanel() {
    this.isOpen = !this.isOpen
  }
}
