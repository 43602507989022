import store from '@/store'
import moment from 'moment-timezone'
import { compress, compressAccurately, dataURLtoImage, EImageType, filetoDataURL } from 'image-conversion'

export const DATE_FORMAT = 'yyyy-MM-DD'
export const DATE_OUTPUT_FORMAT = 'yyyy年MM月DD日'

export function dformat(date: string | Date | moment.Moment) {
  return moment(date).format(DATE_FORMAT)
}

export function doutformat(date: string | Date | moment.Moment) {
  return moment(date).format(DATE_OUTPUT_FORMAT)
}

export function isDateBetween(date?: string | Date | moment.Moment, startDate?: string | Date | moment.Moment, endDate?: string | Date | moment.Moment, inclusive?: boolean): boolean {
  return moment(date).isBetween(moment(startDate), moment(endDate), undefined, inclusive ? '[]' : undefined)
}

// Downscales a data URI to the requested max width/heigh
// if needed, and at the requested quality.
export async function downscaleImage(
  file: Blob,
  resolution: number, // max width/height in pixels
  newSizeKb?: number // the size in kb of the new file, if requested
): Promise<Blob> {
  const dataUri = await filetoDataURL(file)

  // Create a temporary image so that we can compute the height of the image.
  const image = await dataURLtoImage(dataUri)
  const oldWidth = image.naturalWidth
  const oldHeight = image.naturalHeight

  const longestDimension = oldWidth > oldHeight ? 'width' : 'height'
  const currentRes = longestDimension === 'width' ? oldWidth : oldHeight

  let newWidth
  let newHeight
  if (currentRes > resolution) {
    // Calculate new dimensions
    const newSize =
      longestDimension === 'width'
        ? Math.floor((oldHeight / oldWidth) * resolution)
        : Math.floor((oldWidth / oldHeight) * resolution)
    newWidth = longestDimension === 'width' ? resolution : newSize
    newHeight = longestDimension === 'height' ? resolution : newSize
  } else {
    newWidth = oldWidth
    newHeight = oldHeight
  }

  let downscaled
  if (newSizeKb) {
    downscaled = await compressAccurately(file, {
      size: newSizeKb,
      width: newWidth,
      height: newHeight,
      type: EImageType.JPEG
    })
  } else {
    downscaled = await compress(file, {
      quality: 1,
      width: newWidth,
      height: newHeight,
      type: EImageType.JPEG
    })
  }
  return downscaled
}

// Converts a blob/file to ArrayBuffer
export function blobToArrayBuffer(blob: Blob | File): Promise<ArrayBuffer> {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result as ArrayBuffer)
    }
    reader.onerror = () => {
      reject(reader.error)
    }
    reader.readAsArrayBuffer(blob)
  })
}

function hasTouchScreen() {
  let hasTouchScreen = false

  if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
    if (navigator.maxTouchPoints > 0) {
      hasTouchScreen = true
    }
  } else {
    if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
      hasTouchScreen = true
    } else if (window.TouchEvent || ('ontouchstart' in window)) {
      hasTouchScreen = true
    }
  }

  return hasTouchScreen
}

async function hasCamera() {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices()
    const hasCamera = devices.find((device) => device.kind === 'videoinput')
    return hasCamera !== undefined
  } catch (error) {
    return false
  }
}

function isMobileBrowser() {
  const isIPadOSBrowser = (navigator.userAgent.includes('Mac') && 'ontouchend' in document) // fix Ipad OS 13 onward detection
  return /\b(iPhone|iPad|iPod|Android)\b/i.test(navigator.userAgent) || isIPadOSBrowser
}

export function isProduction() {
  const hostname = window.location.hostname
  return hostname.match('.*(local|dev|qa).*') === null
}

export async function isMobileDevice() {
  return isMobileBrowser() && hasTouchScreen() && await hasCamera()
}

export function isSuspendRegistration() {
  return store.getters['app/suspendRegistration']
}

export function isDeactivateCancelCoverage() {
  return store.getters['app/deactivateCancelCoverage']
}

export function isDeactivateUpdatePayment() {
  return store.getters['app/deactivateUpdatePayment']
}

export function isDeactivateAddNewDevice() {
  return store.getters['app/deactivateAddNewDevice']
}

export function modalBeforeOpenHandling() {
  document.documentElement.classList.add('lock')
}

export function modalBeforeCloseHandling(restorePoint: number) {
  document.documentElement.classList.remove('lock')
  if (restorePoint > 0) {
    window.scrollTo(0, restorePoint)
  }
}

export const isMediaDeviceSupport = () => {
  return location.protocol === 'https:' && 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices
}

export const formatExternalLink = (link: string) => {
  if (store.state.app?.affId) {
    return `${link}?aff_id=${store.state.app?.affId}`
  }

  return link
}
