























import constants from '@/constants'
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component({
  name: 'TerminationNotice'
})
export default class TerminationNotice extends Vue {
  @Getter('app/showTermination')
  showTermination!: boolean

  @Getter('app/showTermination2')
  showTermination2!: boolean

  openTerminationModal() {
    this.$modal.show(constants.modalNames.TERMINATION_NOTICE_MODAL)
  }

  openTerminationServiceModal() {
    this.$modal.show(constants.modalNames.TERMINATION_SERVICE_MODAL)
  }
}
