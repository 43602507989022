export default class RecaptchaModel {
  private readonly _valid: boolean
  private readonly _invalidReason: string
  private readonly _score: string

  constructor(valid: boolean, invalidReason: string, score: string) {
    this._valid = valid
    this._invalidReason = invalidReason
    this._score = score
  }

  get valid(): boolean {
    return this._valid
  }

  get score(): string {
    return this._score
  }

  get invalidReason(): string {
    return this._invalidReason
  }
}
