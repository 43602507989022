import { xhr } from '../Auth'
import { Config } from '@/config'
import {
  V2ProductApi,
  V2ProductApiInterface
} from 'shift-product-service-api-client'
import { ApiFactory } from '@uncharted/coverhub-framework'

const products = ApiFactory<V2ProductApiInterface, V2ProductApi>(
  V2ProductApi,
  Config.shift.url,
  xhr
)

export { products as Products }
