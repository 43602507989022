

































import { Component, Vue } from 'vue-property-decorator'
import constants from '@/constants'
import AonButton from '@/components/AonButton.vue'
import { modalBeforeCloseHandling, modalBeforeOpenHandling } from '@/services/functions'

@Component({
  components: { AonButton }
})
export default class SupportModal extends Vue {
  name = constants.modalNames.NINTENDO_SUPPORT_MODAL
  supportSampleImg = require('@/assets/nintendo-support.jpeg')

  onCancel () {
    this.$modal.hide(this.name)
  }

  openSupportPage () {
    window.open('https://support.nintendo.co.jp/app/answers/detail/a_id/34014', '_blank')
  }

  private restorePoint = 0

  beforeOpen() {
    this.restorePoint = document?.documentElement?.scrollTop || 0
    modalBeforeOpenHandling()
  }

  beforeClose() {
    modalBeforeCloseHandling(this.restorePoint)
  }
}
