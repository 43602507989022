export const SET_PRODUCT_MODEL = 'SET_PRODUCT_MODEL'
export const SET_SALES = 'SET_SALES'
export const SET_PRODUCT_LOADED = 'SET_PRODUCT_LOADED'
export const SET_FACT_VALUE = 'SET_FACT_VALUE'
export const SET_VALIDATE = 'SET_VALIDATE'
export const SET_SERIALIZED_CONFIG = 'SET_SERIALIZED_CONFIG'
export const SET_PRODUCT_STATE = 'SET_PRODUCT_STATE'
export const SET_INVOICE_ID = 'SET_INVOICE_ID'
export const SET_CONVERT_QUOTE = 'SET_CONVERT_QUOTE'
export const SET_BUNDLE = 'SET_BUNDLE'
export const RESET_SALE_JOURNEY_STATE = 'RESET_SALE_JOURNEY_STATE'
export const SET_JOURNEY_STATE_PROPOSAL_SUBMITED = 'SET_JOURNEY_STATE_PROPOSAL_SUBMITED'
export const SET_JOURNEY_STATE_QUOTE_CREATED = 'SET_JOURNEY_STATE_QUOTE_CREATED'
export const SET_JOURNEY_STATE_POLICY_CREATED = 'SET_JOURNEY_STATE_POLICY_CREATED'
export const SET_JOURNEY_STATE_INVOICE_AVAILABLE = 'SET_JOURNEY_STATE_INVOICE_AVAILABLE'
export const SET_JOURNEY_STATE_PAYMENT_STARTED = 'SET_JOURNEY_STATE_PAYMENT_STARTED'
export const SET_JOURNEY_STATE_GMO_OK = 'SET_JOURNEY_STATE_GMO_OK'
export const SET_JOURNEY_STATE_PAYMENT_COMPLETED = 'SET_JOURNEY_STATE_PAYMENT_COMPLETED'
