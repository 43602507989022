import { AppState } from '@/store/api/AppState'
import { RootState } from '@/store/api/RootState'
import { GetterTree } from 'vuex'

export const getters: GetterTree<AppState, RootState> = {
  showTermination(state: AppState): boolean {
    return state.showTermination
  },
  showTermination2(state: AppState): boolean {
    return state.showTermination2
  },
  showTermAndCondition(state: AppState): boolean {
    return state.showTermAndCondition
  },
  disablePlanChange(state: AppState): boolean {
    return state.disablePlanChange
  },
  suspendRegistration(state: AppState): boolean {
    return state.suspendRegistration
  },
  deactivateAddNewDevice(state: AppState): boolean {
    return state.deactivateAddNewDevice
  },
  deactivateUpdatePayment(state: AppState): boolean {
    return state.deactivateUpdatePayment
  },
  deactivateCancelCoverage(state: AppState): boolean {
    return state.deactivateCancelCoverage
  },
  extraWaitingMessage(state: AppState): boolean {
    return state.extraWaitingMessage
  },
  servicNotice2(state: AppState): boolean {
    return state.servicNotice2
  },
  newTermUse(state: AppState): boolean {
    return state.newTermUse
  }
}
