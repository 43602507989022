

































import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import TermOfUseModal from '@/components/modals/TermOfUseModal.vue'
import NewTermOfUseModal from '@/components/modals/NewTermOfUseModal.vue'
import PrivacyPolicyModal from '@/components/modals/PrivacyPolicyModal.vue'
import AccountPolicyModal from '@/components/modals/AccountPolicyModal.vue'
import NoticesModal from '@/components/modals/NoticesModal.vue'
import ServiceNoticesModal from '@/components/modals/ServiceNoticesModal.vue'
import ErrorMessageModal from '@/components/modals/ErrorMessageModal.vue'
import SupportModal from '@/components/modals/SupportModal.vue'
import ServiceIntroductionModal from '@/components/modals/external-links/ServiceIntroductionModal.vue'
import CommercialTransactionsModal from '@/components/modals/external-links/CommercialTransactionsModal.vue'
import TerminationServiceModal from '@/components/modals/external-links/TerminationServiceModal.vue'
import TermAndConditionServiceModal from '@/components/modals/external-links/TermAndConditionServiceModal.vue'
import FaqModal from '@/components/modals/external-links/FaqModal.vue'
import ImageViewer from '@/components/ImageViewer.vue'
import LoadIndicator from '@/components/LoadIndicator.vue'
import { EventBus as aonEventBus } from '@/plugins/eventBus'
import {
  AuthEvent,
  EventBus,
  HttpError,
  XHR_EVENT_NAMES,
  AUTH_EVENT_NAMES
} from '@uncharted/coverhub-framework'
import routeNames from '@/constants/routeNames'
import { Auth } from '@/services/Auth'
import { Config } from './config'
import constants from './constants'
import { ACTION_POLICY_SET_LANGUAGE } from './store/modules/policy/actionTypes'
import { ACTION_PRODUCT_SET_LANGUAGE } from './store/modules/product/actionTypes'
import { ACTION_LOGOUT } from '@/store/modules/user/actionTypes'
import Idle from '@/components/Idle.vue'
import { ACTION_GET_SHOW_TERMINATION, ACTION_VERSION_CHECK } from './store/modules/app/actionTypes'
import TerminationNoticeModal from './components/modals/TerminationNoticeModal.vue'
import TermAndConditionNoticeModal from './components/modals/TermAndConditionNoticeModal.vue'
import { Getter, State } from 'vuex-class'
import { AppState } from './store/api/AppState'

@Component({
  name: 'App',
  components: {
    Header,
    Footer,
    TermOfUseModal,
    PrivacyPolicyModal,
    AccountPolicyModal,
    NoticesModal,
    ServiceNoticesModal,
    ErrorMessageModal,
    ServiceIntroductionModal,
    CommercialTransactionsModal,
    FaqModal,
    LoadIndicator,
    ImageViewer,
    SupportModal,
    Idle,
    TerminationNoticeModal,
    TerminationServiceModal,
    TermAndConditionServiceModal,
    TermAndConditionNoticeModal,
    NewTermOfUseModal
  }
})
export default class App extends Vue {
  @State private app!: AppState

  private ticker!: NodeJS.Timeout
  private versionControlTicker!: NodeJS.Timeout
  versionTime = 600000 // in 10 min
  showContent = true
  sideMenutransitionName = constants.transitionNames.LEFT_SLIDE_OUT
  pageTransitionName = constants.transitionNames.FADE

  @Getter('app/showTermination')
  showTermination!: boolean

  @Getter('app/showTermAndCondition')
  showTermAndCondition!: boolean

  @Getter('app/newTermUse')
  newTermUse!: boolean

  toggleContent(isShow = true) {
    this.sideMenutransitionName = isShow
      ? constants.transitionNames.LEFT_SLIDE_IN
      : constants.transitionNames.LEFT_SLIDE_OUT
    this.$nextTick(() => {
      this.showContent = isShow
    })
  }

  changeLanguage() {
    const currentLocale = this.$i18n.locale
    this.$i18n.locale = currentLocale === 'ja' ? 'en' : 'ja'
    Config.locale = this.$i18n.locale
    this.setTitle()
    this.$store.dispatch(ACTION_POLICY_SET_LANGUAGE, Config.locale)
    this.$store.dispatch(ACTION_PRODUCT_SET_LANGUAGE, Config.locale)
  }

  inProtectedRoute() {
    return (
      this.$route.path.startsWith('/auth') ||
      this.$route.path.startsWith('/application/auth')
    )
  }

  async created() {
    this.onHttpError = this.onHttpError.bind(this)
    this.onAuthEvent = this.onAuthEvent.bind(this)
    aonEventBus.$on(constants.eventNames.OPEN_MENU, () =>
      this.toggleContent(false)
    )
    this.setTitle()
    this.$store.dispatch(ACTION_GET_SHOW_TERMINATION)
    aonEventBus.$on(constants.eventNames.CLOSE_MENU, () => this.toggleContent())
    EventBus.on(XHR_EVENT_NAMES.HTTP_ERROR, this.onHttpError)
    EventBus.on(AUTH_EVENT_NAMES.AUTH_EVENT, this.onAuthEvent)
    this.ticker = setInterval(() => {
      if (this.inProtectedRoute()) {
        Auth.loggedInAs('otp')
      }
    }, 5000)

    this.$router.beforeEach((to, from, next) => {
      if (from.meta && 'pageIndex' in from.meta && to.meta && 'pageIndex' in to.meta) {
        this.pageTransitionName = to.meta.pageIndex > from.meta.pageIndex ? constants.transitionNames.SLIDE_LEFT : constants.transitionNames.SLIDE_RIGHT
      } else {
        this.pageTransitionName = constants.transitionNames.FADE
      }
      next()
    })

    if (Config.environment === 'production') {
      this.$store.dispatch(ACTION_VERSION_CHECK)
      this.versionControlTicker = setInterval(() => {
        this.$store.dispatch(ACTION_VERSION_CHECK)
      }, this.versionTime)
    }
  }

  onResize() {
    document.documentElement.style.setProperty(
      '--window-inner-height',
      `${window.innerHeight}px`
    )
  }

  mounted() {
    window.addEventListener('resize', this.onResize)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }

  destroyed() {
    clearInterval(this.ticker)
    clearInterval(this.versionControlTicker)
    EventBus.off(XHR_EVENT_NAMES.HTTP_ERROR, this.onHttpError)
    EventBus.off(AUTH_EVENT_NAMES.AUTH_EVENT, this.onAuthEvent)
    aonEventBus.$off(constants.eventNames.CLOSE_MENU)
    aonEventBus.$off(constants.eventNames.OPEN_MENU)
  }

  onHttpError(evt: HttpError) {
    // Fix infinite loop error
    if (this.$route.name !== routeNames.ERROR) {
      const error = `${evt.status} ${evt.statusText}`
      const query = { error }
      if (evt.status === 401) {
        this.$router.push({ name: routeNames.SIGN_IN })
      } else {
        switch (evt.status) {
          case 404:
          case 409:
            // do nothing
            break
          default:
            this.$router.push({ name: routeNames.ERROR, query })
            break
        }
      }
    }
  }

  onAuthEvent(evt: AuthEvent) {
    if (this.$route.name !== routeNames.ERROR) {
      if (evt.name === 'token-expired' && evt.strategy === 'otp') {
        this.$store.dispatch(ACTION_LOGOUT)
      }
      if (evt.name !== 'login' && evt.strategy === 'otp') {
        this.$router.push({ name: routeNames.EXPIRE })
      }
    }
  }

  private setTitle() {
    document.title = this.$i18n.t('common.title').toString()
  }
}
