import { ClaimState } from '@/store/api/ClaimState'
import { MutationTree } from 'vuex'
import {
  SET_CLAIM_STATE,
  SET_CLAIM_OVERLAYS,
  SET_NEW_SERIALIZED,
  SET_REPAIR_POLICY_MODEL,
  SET_CLAIM_FACT_VALUE,
  SET_CLAIM_VALIDATE,
  SET_SUBMITTED_CLAIM,
  SET_REPAIRS,
  SET_FACTS,
  SET_CONSENT
} from './mutationTypes'
import { ClaimOverlay } from '@uncharted/coverhub-framework'
import { SwitchPolicyModel } from '@/services/api/models/SwitchPolicyModel'
import { FactValueChange } from '@/store/api/models/FactValueChange'
import { BenefitViolation, FactDescription, ValidationError } from '@/interfaces/engine-exports'
import { SubmittedClaim } from '@/store/api/models/SubmittedClaim'
import { RepairModel } from '@/services/api/models/RepairModel'

export const mutations: MutationTree<ClaimState> = {
  [SET_CLAIM_OVERLAYS](state: ClaimState, payload: ClaimOverlay[]) {
    state.overlays = payload
  },
  [SET_REPAIR_POLICY_MODEL](state: ClaimState, payload: SwitchPolicyModel) {
    state.repairPolicyModel = payload
  },
  [SET_NEW_SERIALIZED](state: ClaimState, payload: string) {
    state.serializedConfig = payload
  },
  [SET_CLAIM_STATE](state: ClaimState, payload: ClaimState) {
    for (const key of Object.keys(payload)) {
      state[key] = payload[key]
    }
  },
  [SET_FACTS](state: ClaimState, payload: FactDescription[]) {
    state.facts = payload
  },
  [SET_CONSENT](state: ClaimState, payload: string) {
    state.consent = payload
  },
  async [SET_CLAIM_FACT_VALUE](state: ClaimState, payload: FactValueChange) {
    // Done by action, so this code will be removed.
    const overlay = state.overlays.find(
      (o) => o.name === payload.groupName
    )
    overlay && overlay.setFactValue(payload.factId, payload.value)
  },
  [SET_CLAIM_VALIDATE](state: ClaimState, payload: string) {
    const overlay = state.overlays.find((o) => o.name === payload)
    let errors: ValidationError[] = []
    let violations: BenefitViolation[] = []
    if (overlay) {
      const res = overlay.validate()
      errors = res.errors
      violations = res.violations
    }
    state.validationErrors = errors
    state.violations = violations
  },
  [SET_SUBMITTED_CLAIM](state: ClaimState, payload: SubmittedClaim) {
    state.submittedClaim = payload
  },
  [SET_REPAIRS](state: ClaimState, payload: RepairModel[]) {
    state.repairs = payload
  }
}
