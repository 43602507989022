












import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component
export default class ApplyButton extends Vue {
  @Prop() route?: string
  @Prop() to?: any
  @Prop() disabled?: boolean

  private clicked = false

  protected onClick (evt: Event) {
    if (!this.disabled) {
      this.clicked = true
      if (this.route) {
        this.$router.push({ name: this.route })
      } else if (this.to) {
        this.$router.push(this.to)
      }
      this.fireClick(evt)
    }
  }

  @Emit('click')
  protected fireClick(evt: Event) {
    return evt
  }

  reset() {
    this.clicked = false
  }
}
