import { PolicyState } from '@/store/api/PolicyState'
import { MutationTree } from 'vuex'
import {
  SET_POLICIES, SET_POLICY_CANCEL_PLAN, SET_POLICY_CERTIFICATE, SET_POLICY_CONVERTED_POLICY, SET_POLICY_PHOTOS,
  SET_POLICY_STATE
} from './mutationTypes'
import { SwitchPolicyModel } from '@/services/api/models/SwitchPolicyModel'
import { CancelPlanModel } from '@/store/api/models/CancelPlanModel'
import { PhotoModel } from '@/store/api/models/PhotoModel'
import { PolicyCertificateModel } from '@/store/api/models/PolicyCertificateModel'
import { PolicyModel } from 'shift-policy-service-api-client'

export const mutations: MutationTree<PolicyState> = {
  [SET_POLICIES](state: PolicyState, payload: SwitchPolicyModel[]) {
    state.policies = payload
    state.loaded = true
  },
  [SET_POLICY_STATE](state: PolicyState, payload: PolicyState) {
    for (const key of Object.keys(payload)) {
      state[key] = payload[key]
    }
  },
  [SET_POLICY_CANCEL_PLAN](state: PolicyState, payload: CancelPlanModel) {
    state.cancelPlan = payload
  },
  [SET_POLICY_PHOTOS](state: PolicyState, payload: PhotoModel) {
    state.photos = payload
  },
  [SET_POLICY_CERTIFICATE](state: PolicyState, payload: PolicyCertificateModel) {
    state.policyCertificate = payload
  },
  [SET_POLICY_CONVERTED_POLICY](state: PolicyState, payload: PolicyModel) {
    state.convertedPolicy = payload
  }
}
