import constants from '@/constants'
import { RouteConfig } from 'vue-router'

export const dashboardRoutes: Array<RouteConfig> = [
  {
    path: '/auth/dashboard',
    name: constants.routeNames.DASHBOARD,
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ '@/views/auth/Dashboard.vue'),
    meta: {
      pageIndex: 0
    }
  }
]
