import { AppState } from '@/store/api/AppState'
import { MutationTree } from 'vuex'
import { SET_APP_VERSION, SET_APP_NEW_VERSION, SET_APP_AFFILIATE_ID, SET_APP_SHOW_TERMINATION, SET_APP_SHOW_TERMINATION2, SET_APP_DISABLE_PLAN_CHANGE, SET_APP_SHOW_TERM_AND_CONDITION, SET_APP_SUSPEND_REGISTRATION, SET_APP_DEACTIVATE_NEW_DEVICE, SET_APP_DEACTIVATE_UPDATE_PAYMENT, SET_APP_DEACTIVATE_CANCEL_COVERAGE, SET_APP_EXTRA_WAITING_MSG, SET_APP_SERVICE_NOTICE_2, SET_APP_NEW_TERM_USE } from './mutationTypes'

export const mutations: MutationTree<AppState> = {
  [SET_APP_VERSION](state: AppState, payload: string) {
    state.version = payload
  },
  [SET_APP_NEW_VERSION](state: AppState, payload: string) {
    state.newVersion = payload
  },
  [SET_APP_AFFILIATE_ID](state: AppState, payload: string) {
    state.affId = payload
  },
  [SET_APP_SHOW_TERMINATION](state: AppState, payload: boolean) {
    state.showTermination = payload
  },
  [SET_APP_SHOW_TERMINATION2](state: AppState, payload: boolean) {
    state.showTermination2 = payload
  },
  [SET_APP_SHOW_TERM_AND_CONDITION](state: AppState, payload: boolean) {
    state.showTermAndCondition = payload
  },
  [SET_APP_DISABLE_PLAN_CHANGE](state: AppState, payload: boolean) {
    state.disablePlanChange = payload
  },
  [SET_APP_SUSPEND_REGISTRATION](state: AppState, payload: boolean) {
    state.suspendRegistration = payload
  },
  [SET_APP_DEACTIVATE_NEW_DEVICE](state: AppState, payload: boolean) {
    state.deactivateAddNewDevice = payload
  },
  [SET_APP_DEACTIVATE_UPDATE_PAYMENT](state: AppState, payload: boolean) {
    state.deactivateUpdatePayment = payload
  },
  [SET_APP_DEACTIVATE_CANCEL_COVERAGE](state: AppState, payload: boolean) {
    state.deactivateCancelCoverage = payload
  },
  [SET_APP_EXTRA_WAITING_MSG](state: AppState, payload: boolean) {
    state.extraWaitingMessage = payload
  },
  [SET_APP_SERVICE_NOTICE_2](state: AppState, payload: boolean) {
    state.servicNotice2 = payload
  },
  [SET_APP_NEW_TERM_USE](state: AppState, payload: boolean) {
    state.newTermUse = payload
  }
}
