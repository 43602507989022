import { Config } from '@/config'
import {
  op,
  FactIdFilter,
  OrFilter,
  DefaultOverlay,
  Catalog,
  Cart,
  Product,
  shift,
  OverlayGroup,
  AndFilter,
  NotFilter,
  Overlay,
  OverlayFactory
} from '@uncharted/coverhub-framework'
import translate from '@/plugins/i18n'

import FactDescription = shift.productengine.description.fact.FactDescription

export const GroupNames = {
  coverage: 'coverage',
  plan: 'plan',
  certificate: 'certificate',
  email: 'email',
  applicant: 'applicant',
  summary: 'summary',
  application: 'application'
}

export type GroupName =
  | 'coverage'
  | 'plan'
  | 'certificate'
  | 'email'
  | 'application'
  | 'applicant'
  | 'summary'

export class Sales {
  private readonly _catalog!: Catalog
  private readonly _cart!: Cart

  constructor(prodDef: string, config?: string) {
    // Filters
    const coverageFilter = new FactIdFilter(
      'COMPONENT:base.coverage.devicetype',
      op.startsWith
    )
    const planFilter = new FactIdFilter('COMPONENT:base.plan', op.startsWith)
    const certFilter = new FactIdFilter(
      'COMPONENT:base.guarantee',
      op.startsWith
    )
    const emailFilter = new FactIdFilter('applicant.email', op.eq)
    const affiliateFilter = new FactIdFilter('applicant.affiliateid', op.eq)
    // The following 3 filters are used on AppForm to segment
    // facts into 3 groups for viewing/layout purposes.
    const applicationApplicantFilter = new AndFilter([
      new FactIdFilter('applicant', op.startsWith),
      new NotFilter(emailFilter),
      new NotFilter(affiliateFilter)
    ])
    const applicationPhotoFrontFilter = new FactIdFilter(
      'COMPONENT:base.deviceregistration.photofront',
      op.startsWith
    )
    const applicationRestFilter = new AndFilter([
      new FactIdFilter('COMPONENT:base.deviceregistration', op.startsWith),
      new NotFilter(new FactIdFilter('COMPONENT:base.deviceregistration.photofront', op.startsWith)),
      new NotFilter(new FactIdFilter('COMPONENT:base.deviceregistration.campaigncode', op.eq)),
      new NotFilter(new FactIdFilter('COMPONENT:base.deviceregistration.discountamount', op.eq)),
      new NotFilter(new FactIdFilter('COMPONENT:base.deviceregistration.intconditiontype', op.eq))
    ])
    // These are used by applicantOverlay, which is an overlay used
    // for setting facts in application form (AppForm)
    const applicantFilter = new OrFilter([
      applicationApplicantFilter,
      applicationPhotoFrontFilter,
      applicationRestFilter
    ])
    const summaryCoverageFilter = new OrFilter([
      new FactIdFilter('virtual:discount', op.eq),
      new FactIdFilter('COMPONENT:base.plan.plan', op.eq),
      new FactIdFilter('COMPONENT:base.deviceregistration.campaigncode', op.eq)
    ])
    const summaryApplicantFilter = new OrFilter(
      ['applicant.firstname', 'applicant.lastname', 'applicant.dob'].map(
        (fid) => new FactIdFilter(fid, op.eq)
      )
    )
    const summaryDeviceFilter = new OrFilter(
      [
        'COMPONENT:base.coverage.devicetype',
        'COMPONENT:base.deviceregistration.nickname',
        'COMPONENT:base.deviceregistration.photofront.serialbody',
        'COMPONENT:base.deviceregistration.photofront.serialjoyconleft',
        'COMPONENT:base.deviceregistration.photofront.serialjoyconright',
        'COMPONENT:base.guarantee.purchasedate'
      ].map((fid) => new FactIdFilter(fid, op.eq))
    )

    // Sorts
    const certOrder = [ // For warranty proof screen
      'COMPONENT:base.guarantee',
      'COMPONENT:base.guarantee.warrantycard',
      'COMPONENT:base.guarantee.purchasedate',
      'COMPONENT:base.guarantee.receipt'
    ]
    function certSort(a: any, b: any) {
      const aIx = certOrder.indexOf(a.id)
      const bIx = certOrder.indexOf(b.id)
      return aIx - bIx
    }

    const summaryDeviceOrder = [ // For summary page, after purchase - Sort order of device info
      'COMPONENT:base.coverage.devicetype',
      'COMPONENT:base.deviceregistration.nickname',
      'COMPONENT:base.deviceregistration.photofront.serialbody',
      'COMPONENT:base.deviceregistration.photofront.serialjoyconleft',
      'COMPONENT:base.deviceregistration.photofront.serialjoyconright',
      'COMPONENT:base.guarantee.purchasedate'
    ]
    function summaryDeviceSort(a: any, b: any) {
      const aIx = summaryDeviceOrder.indexOf(a.id)
      const bIx = summaryDeviceOrder.indexOf(b.id)
      return aIx - bIx
    }

    // Overlays
    const coverageOverlay = new DefaultOverlay('coverage', coverageFilter)
    const planOverlay = new DefaultOverlay('plan', planFilter)
    const certOverlay = new DefaultOverlay(
      'certificate',
      certFilter,
      undefined,
      [],
      undefined,
      certSort
    )
    const emailOverlay = new DefaultOverlay('email', emailFilter)
    const applicantOverlay = new DefaultOverlay('applicant', applicantFilter)

    const applicationOverlayGroup = new OverlayGroup('application', [
      new DefaultOverlay('application.applicant', applicationApplicantFilter),
      new DefaultOverlay('application.photofront', applicationPhotoFrontFilter),
      new DefaultOverlay('application.rest', applicationRestFilter)
    ])

    function discountVirtual(overlay: Overlay): FactDescription[] {
      const price = overlay.getProducts()[0].calculatePrice()
      const discount = price.buckets.reduce<string>((acc, b) => {
        if (b.id === 'discount') {
          acc = translate
            .t('common.currencySymbol', { amount: Math.round(b.total) * -1 })
            .toString()
        }
        return acc
      }, '')
      return [
        OverlayFactory.createVirtualFact(
          'virtual:discount',
          translate.t('fact.virtual.discount.name').toString(),
          discount
        ),
        OverlayFactory.createVirtualFact(
          'virtual:price',
          translate.t('fact.virtual.totalPrice.name').toString(),
          translate
            .t('common.currencySymbol', { amount: price.totalPriceInclTax })
            .toString()
        )
      ]
    }
    const summaryOverlayGroup = new OverlayGroup('summary', [
      new DefaultOverlay(
        'summary.coverage',
        summaryCoverageFilter,
        undefined,
        [],
        discountVirtual
      ),
      new DefaultOverlay('summary.applicant', summaryApplicantFilter),
      new DefaultOverlay(
        'summary.device',
        summaryDeviceFilter,
        undefined,
        [],
        undefined,
        summaryDeviceSort
      )
    ])

    this._catalog = new Catalog([
      new Product({
        definition: prodDef,
        serialized: config,
        language: Config.locale
      })
    ])
    this._cart = new Cart([
      coverageOverlay.toOverlayGroup(GroupNames.coverage),
      planOverlay.toOverlayGroup(GroupNames.plan),
      certOverlay.toOverlayGroup(GroupNames.certificate),
      emailOverlay.toOverlayGroup(GroupNames.email),
      applicationOverlayGroup,
      applicantOverlay.toOverlayGroup(GroupNames.applicant),
      summaryOverlayGroup
    ])
  }

  get cart() {
    return this._cart
  }

  get catalog() {
    return this._catalog
  }
}
