export const ACTION_LOAD_PRODUCT = 'product/loadProduct'
export const ACTION_RELOAD_PRODUCT = 'product/reloadProduct'
export const ACTION_SET_PRODUCT_LOADED = 'product/setProductLoaded'
export const ACTION_RESET_PRODUCT_SERIALIZED_CONFIG = 'product/resetProductSerializedConfig'
export const ACTION_FACT_VALUE_SET = 'product/factValueSet'
export const ACTION_VALIDATE_PRODUCT = 'product/validate'
export const ACTION_NEW_COVERAGE = 'product/newCoverage'
export const ACTION_RESET_PRODUCT_STATE = 'product/resetState'
export const ACTION_SET_INVOICE_ID = 'product/setInvoiceId'
export const ACTION_PRODUCT_SET_LANGUAGE = 'product/setLanguage'
export const ACTION_PRODUCT_EMPTY_CART = 'product/emptyCart'
export const ACTION_SET_CONVERT_QUOTE = 'product/setConvertQuote'
export const ACTION_SET_BUNDLE = 'product/setBundle'
export const ACTION_RESET_SALE_JOURNEY_STATE = 'product/resetSaleJourneyState'
export const ACTION_SET_SALE_JOURNEY_STATE_PROPOSAL_SUBMITTED = 'product/setSaleJourneyStateProposalSubmitted'
export const ACTION_SET_SALE_JOURNEY_STATE_QUOTE_CREATED = 'product/setSaleJourneyStateQuoteCreated'
export const ACTION_SET_SALE_JOURNEY_STATE_POLICY_CREATED = 'product/setSaleJourneyStatePolicyCreated'
export const ACTION_SET_SALE_JOURNEY_STATE_INVOICE_AVAILABLE = 'product/setSaleJourneyStateInvoiceAvailable'
export const ACTION_SET_SALE_JOURNEY_STATE_PAYMENT_STARTED = 'product/setSaleJourneyStatePaymentStarted'
export const ACTION_SET_SALE_JOURNEY_GMO_OK = 'product/setSaleJourneyGmoOK'
export const ACTION_SET_SALE_JOURNEY_STATE_PAYMENT_COMPLETED = 'product/setSaleJourneyStatePaymentCompleted'
