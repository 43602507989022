import { keepOnErrorAxios } from '../Auth'
import { Config } from '@/config'
import { ApiFactory } from '@uncharted/coverhub-framework'
import {
  V1ContentApi,
  V1ContentApiInterface
} from 'shift-content-mgmt-service-api-client'

const cms = ApiFactory<
V1ContentApiInterface,
V1ContentApi
>(V1ContentApi, Config.shift.url, keepOnErrorAxios)

export { cms as Cms }
