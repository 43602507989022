import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { product } from '@/store/modules/product'
import { policy } from '@/store/modules/policy'
import { user } from '@/store/modules/user'
import { claim } from '@/store/modules/claim'
import { app } from '@/store/modules/app'

Vue.use(Vuex)

const storeKey = 'coverhub-aon-nintendo'

export default new Vuex.Store({
  modules: {
    app,
    product,
    policy,
    user,
    claim
  },
  plugins: [
    // keys that should NOT persist after logout
    createPersistedState({
      key: storeKey,
      paths: [
        'app.affId',
        'user',
        'product.serializedConfig',
        'product.bundle',
        'product.convertQuote',
        'product.invoiceId',
        'product.saleJourneyState',
        'product.validatedOverlayGroups',
        'claim.serializedConfig',
        'policy.convertedPolicy'
      ],
      storage: window.sessionStorage
    })
  ]
})
