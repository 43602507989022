














import { formatExternalLink } from '@/services/functions'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ExternalLinkModal extends Vue {
  @Prop() infoTextKey!: string
  @Prop() buttonTextKey!: string
  @Prop() buttonUrl!: string

  onCancel () {
    this.$emit('cancel')
  }

  goToUrl () {
    window.open(formatExternalLink(this.buttonUrl), '_blank')
  }
}
