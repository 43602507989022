import { ConvertQuoteModel, PolicyBundleModel } from 'shift-policy-service-api-client'
import { MutationTree } from 'vuex'
import { ProductState } from '@/store/api/ProductState'
import {
  SET_PRODUCT_MODEL,
  SET_SALES,
  SET_PRODUCT_LOADED,
  SET_FACT_VALUE,
  SET_VALIDATE,
  SET_SERIALIZED_CONFIG, SET_PRODUCT_STATE, SET_INVOICE_ID,
  RESET_SALE_JOURNEY_STATE,
  SET_JOURNEY_STATE_PROPOSAL_SUBMITED,
  SET_JOURNEY_STATE_QUOTE_CREATED,
  SET_JOURNEY_STATE_POLICY_CREATED,
  SET_JOURNEY_STATE_INVOICE_AVAILABLE,
  SET_JOURNEY_STATE_PAYMENT_STARTED,
  SET_JOURNEY_STATE_PAYMENT_COMPLETED,
  SET_BUNDLE,
  SET_CONVERT_QUOTE,
  SET_JOURNEY_STATE_GMO_OK
} from './mutationTypes'
import { ProductModel } from 'shift-product-service-api-client'
import { GroupName, Sales } from '@/services/Sales'
import { FactValueChange } from '@/store/api/models/FactValueChange'
import { ValidationError, ValidationResponse } from '@/interfaces/engine-exports'
// import { shift } from 'product-engine'
// import ValidationResponse = shift.productengine.engine.validation.ValidationResponse
// import ValidationError = shift.productengine.engine.validation.ValidationError

export const mutations: MutationTree<ProductState> = {
  [SET_PRODUCT_MODEL](state: ProductState, payload: ProductModel) {
    state.productModel = payload
  },
  [SET_SALES](state: ProductState, payload: Sales) {
    state.sales = payload
  },
  [SET_PRODUCT_LOADED](state: ProductState, payload: boolean) {
    state.loaded = payload
  },
  [SET_FACT_VALUE](state: ProductState, payload: FactValueChange) {
    /* eslint-disable no-unused-expressions */
    const group =
      state.sales?.cart.overlayGroups.find(
        (g) => g.name === payload.groupName
      ) || null
    if (group) {
      group.overlays[0].setFactValue(payload.factId, payload.value)
    }
  },
  [SET_SERIALIZED_CONFIG](state: ProductState, payload: string) {
    state.serializedConfig = payload
  },
  [SET_VALIDATE](state: ProductState, payload: GroupName) {
    state.sales?.cart.forceUpdate()
    // Validate change
    let evtErrors: ValidationError[] = []
    const group =
      state.sales?.cart.overlayGroups.find((g) => g.name === payload) || null
    if (group) {
      group.overlays.forEach((o) => {
        const resp = o.validate()
        const error = resp.reduce<ValidationError[]>(
          (
            acc: ValidationError[],
            vr: ValidationResponse
          ): ValidationError[] => {
            return [...acc, ...vr.errors]
          },
          []
        )
        evtErrors = [...evtErrors, ...error]
      })
    }
    state.validationErrors = evtErrors
  },
  [SET_PRODUCT_STATE](state: ProductState, payload: ProductState) {
    for (const key of Object.keys(payload)) {
      state[key] = payload[key]
    }
  },
  [SET_INVOICE_ID](state: ProductState, invoiceId: string) {
    state.invoiceId = invoiceId
  },
  [SET_CONVERT_QUOTE](state: ProductState, convertQuote: ConvertQuoteModel | null) {
    state.convertQuote = convertQuote
  },
  [RESET_SALE_JOURNEY_STATE](state: ProductState) {
    state.saleJourneyState.isProposalSubmited = false
    state.saleJourneyState.isQuotedCreated = false
    state.saleJourneyState.isPolicyCreated = false
    state.saleJourneyState.isInvoiceIdAvailable = false
    state.saleJourneyState.isPaymentStarted = false
    state.saleJourneyState.isGmoOk = false
    state.saleJourneyState.isPaymentCompleted = false
  },
  [SET_JOURNEY_STATE_PROPOSAL_SUBMITED](state: ProductState, payload: boolean) {
    state.saleJourneyState.isProposalSubmited = payload
  },
  [SET_JOURNEY_STATE_QUOTE_CREATED](state: ProductState, payload: boolean) {
    state.saleJourneyState.isQuotedCreated = payload
  },
  [SET_JOURNEY_STATE_POLICY_CREATED](state: ProductState, payload: boolean) {
    state.saleJourneyState.isPolicyCreated = payload
  },
  [SET_JOURNEY_STATE_INVOICE_AVAILABLE](state: ProductState, payload: boolean) {
    state.saleJourneyState.isInvoiceIdAvailable = payload
  },
  [SET_JOURNEY_STATE_PAYMENT_STARTED](state: ProductState, payload: boolean) {
    state.saleJourneyState.isPaymentStarted = payload
  },
  [SET_JOURNEY_STATE_GMO_OK](state: ProductState, payload: boolean) {
    state.saleJourneyState.isGmoOk = payload
  },
  [SET_JOURNEY_STATE_PAYMENT_COMPLETED](state: ProductState, payload: boolean) {
    state.saleJourneyState.isPaymentCompleted = payload
  },
  [SET_BUNDLE](state: ProductState, payload: PolicyBundleModel | null) {
    state.bundle = payload
  }
}
