









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Panel extends Vue {
  @Prop({ default: 'white' }) color!: string
  @Prop({ default: true }) bordered!: boolean
}
