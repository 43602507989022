export default {
  INFO_SERIAL_FRONT_MODAL: 'INFO_SERIAL_FRONT_MODAL',
  INFO_SERIAL_BACK_MODAL: 'INFO_SERIAL_BACK_MODAL',
  INFO_WARRANTY_MODAL: 'INFO_WARRANTY_MODAL',
  INFO_POC_MODAL: 'INFO_POC_MODAL',
  DEVICE_CLAIM_ERROR_MODAL: 'DEVICE_CLAIM_ERROR_MODAL',
  DEVICE_IN_STAND_DOWN_ERROR_MODAL: 'DEVICE_IN_STAND_DOWN_ERROR_MODAL',
  PRIVACY_POLICY_MODAL: 'PRIVACY_POLICY_MODAL',
  ACCOUNT_POLICY_MODAL: 'ACCOUNT_POLICY_MODAL',
  NOTICES_MODAL: 'NOTICES_MODAL',
  SERVICE_NOTICES_MODAL: 'SERVICE_NOTICES_MODAL',
  TERM_OF_USE_MODAL: 'TERM_OF_USE_MODAL',
  NEW_TERM_OF_USE_MODAL: 'NEW_TERM_OF_USE_MODAL',
  ERROR_MESSAGE_MODAL: 'ERROR_MESSAGE_MODAL',
  NINTENDO_SUPPORT_MODAL: 'NINTENDO_SUPPORT_MODAL',
  SERVICE_INTRODUCTION_MODAL: 'SERVICE_INTRODUCTION_MODAL',
  COMMERCIAL_TRANSACTIONS_MODAL: 'COMMERCIAL_TRANSACTIONS_MODAL',
  FAQ_MODAL: 'FAQ_MODAL',
  SHIPPING_COMPANY_MODAL: 'SHIPPING_COMPANY_MODAL',
  TERMINATION_NOTICE_MODAL: 'TERMINATION_NOTICE_MODAL',
  TERMINATION_SERVICE_MODAL: 'TERMINATION_SERVICE_MODAL',
  TNC_NOTICE_MODAL: 'TNC_NOTICE_MODAL',
  TNC_SERVICE_MODAL: 'TNC_SERVICE_MODAL'
}
