import constants from '@/constants'
import { isDeactivateCancelCoverage } from '@/services/functions'
import store from '@/store'
import {
  ACTION_LOAD_POLICY_CERTIFICATE,
  ACTION_POLICY_LOAD_CANCEL_PLAN,
  ACTION_POLICY_LOAD_IMAGE_URLS,
  ACTION_LOAD_POLICIES
} from '@/store/modules/policy/actionTypes'
import { RouteConfig } from 'vue-router'

export const policyRoutes: Array<RouteConfig> = [
  {
    path: '/auth/policy',
    beforeEnter(to, from, next) {
      store.dispatch(ACTION_LOAD_POLICIES)
        .then(next)
        .catch(e => {
          console.error(e)
          next({ name: constants.routeNames.ERROR })
        })
    },
    // name: 'Policy',
    component: () =>
      import(/* webpackChunkName: "policy" */ '@/views/auth/Policy/Index.vue'),
    meta: {
      pageIndex: 1
    },
    children: [
      {
        path: '',
        name: constants.routeNames.POLICY_LIST,
        component: () =>
          import(
            /* webpackChunkName: "policy" */ '@/views/auth/Policy/PolicyList.vue'
          ),
        meta: {
          pageIndex: 1.1
        }
      },
      {
        path: ':id',
        component: () =>
          import(
            /* webpackChunkName: "policy" */ '@/views/auth/Policy/PolicyIndex.vue'
          ),
        meta: {
          pageIndex: 1.2
        },
        children: [
          {
            path: '',
            name: constants.routeNames.POLICY_DETAIL,
            component: () =>
              import(
                /* webpackChunkName: "policy" */ '@/views/auth/Policy/PolicyDetail.vue'
              ),
            beforeEnter(to, from, next) {
              store.dispatch(ACTION_POLICY_LOAD_IMAGE_URLS, to.params.id)
                .then(next)
                .catch(e => {
                  console.error(e)
                  next({ name: constants.routeNames.ERROR })
                })
            },
            meta: {
              pageIndex: 1.3
            }
          },
          {
            path: 'cancelCoverage',
            name: constants.routeNames.CANCEL_COVERAGE,
            component: () =>
              import(
                /* webpackChunkName: "policy" */ '@/views/auth/Policy/CancelCoverage.vue'
              ),
            beforeEnter(to, from, next) {
              if (isDeactivateCancelCoverage()) next({ name: constants.routeNames.ERROR })

              store.dispatch(ACTION_POLICY_LOAD_CANCEL_PLAN, to.params.id)
                .then(next)
                .catch((e) => {
                  console.error(e)
                  next({ name: constants.routeNames.ERROR })
                })
            },
            meta: {
              pageIndex: 1.4
            }
          },
          {
            path: 'cancelSuccess',
            name: constants.routeNames.CANCEL_COVERAGE_SUCCESS,
            component: () =>
              import(
                /* webpackChunkName: "policy" */ '@/views/auth/Policy/CancelCoverageSuccess.vue'
              ),
            meta: {
              pageIndex: 1.41
            }
          },
          {
            path: 'changePlan',
            name: constants.routeNames.CHANGE_PLAN,
            component: () =>
              import(
                /* webpackChunkName: "policy" */ '@/views/auth/Policy/ChangePlan.vue'
              ),
            meta: {
              pageIndex: 1.5
            }
          },
          {
            path: 'changePlanSuccess',
            name: constants.routeNames.CHANGE_PLAN_SUCCESS,
            component: () =>
              import(
                /* webpackChunkName: "policy" */ '@/views/auth/Policy/ChangePlanSuccess.vue'
              ),
            meta: {
              pageIndex: 1.51
            }
          },
          {
            path: 'changePlanFailure',
            name: constants.routeNames.CHANGE_PLAN_FAILURE,
            component: () =>
              import(
                /* webpackChunkName: "policy" */ '@/views/auth/Policy/ChangePlanFailure.vue'
              ),
            meta: {
              pageIndex: 1.51
            }
          },
          {
            path: 'certificate',
            name: constants.routeNames.POLICY_CERTIFICATE,
            beforeEnter(to, from, next) {
              store.dispatch(ACTION_LOAD_POLICY_CERTIFICATE, to.params.id)
                .then(next)
                .catch(e => {
                  console.error(e)
                  next({ name: constants.routeNames.ERROR })
                })
            },
            component: () =>
              import(
                /* webpackChunkName: "policy" */ '@/views/auth/Policy/PolicyCertificate.vue'
              ),
            meta: {
              pageIndex: 1.6
            }
          }
        ]
      }
    ]
  }
]
