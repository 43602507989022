import { otpXhr } from '../Auth'
import { Config } from '@/config'
import { ApiFactory } from '@uncharted/coverhub-framework'
import { V1ClaimApi, V1ClaimApiInterface } from 'shift-claim-service-api-client'

export const Claims = ApiFactory<V1ClaimApiInterface, V1ClaimApi>(
  V1ClaimApi,
  Config.shift.url,
  otpXhr
)
