import { EventBus } from '@/plugins/eventBus'
import Vue from 'vue'
import constants from '@/constants'

Vue.prototype.ROUTE_NAMES = constants.routeNames

Vue.prototype.$showErrorPopUp = (message: string, onCloseCallbackFn: Function | undefined = () => false): void => {
  EventBus.$emit(constants.eventNames.SHOW_ERROR_MESSAGE, { message, onCloseCallbackFn })
}

Vue.prototype.$viewImage = (name: string, url: string): void => {
  EventBus.$emit(constants.eventNames.SHOW_IMAGE_VIEWER, { name, url })
}
