import { GetterTree } from 'vuex'
import { ProductState } from '@/store/api/ProductState'
import { RootState } from '@/store/api/RootState'
import {
  Catalog,
  Cart,
  Overlay,
  OverlayGroup
} from '@uncharted/coverhub-framework'
import { GroupName } from '@/services/Sales'
import { ValidationError } from '@/interfaces/engine-exports'

export interface FnOverlayByGroupName {
  (groupName: GroupName): Overlay | null;
}

export interface FnOverlayGroupByName {
  (groupName: GroupName): OverlayGroup;
}

export interface FnValidationErrors {
  (): ValidationError[];
}

export const getters: GetterTree<ProductState, RootState> = {
  // This one is probably not needed
  getCatalog(state: ProductState): Catalog | null {
    return state.sales?.catalog || null
  },
  cart(state: ProductState): Cart | null {
    return state.sales?.cart || null
  },
  cartContents(state: ProductState) {
    return state.sales?.cart.contents[0].productEngine
  },
  getOverlayByGroupName: (state: ProductState) => (
    groupName: GroupName
  ): Overlay | null => {
    const group =
      state.sales?.cart?.overlayGroups.find((g) => g.name === groupName) || null
    return group?.overlays[0] || null
  },
  getOverlayGroupByName: (state: ProductState) => (
    groupName: GroupName
  ): OverlayGroup => {
    return (
      // eslint-disable-next-line
      state.sales!.cart.overlayGroups.find((g) => g.name === groupName)!
    )
  },
  getValidationErrors: (state: ProductState) => (): ValidationError[] => {
    return state.validationErrors
  }
}
