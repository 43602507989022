










import { Component, Vue } from 'vue-property-decorator'
import { EventBus, PROGRESS_EVENT_NAMES } from '@uncharted/coverhub-framework'
import { POLLING_NAMES } from '@/constants/polling'

@Component
export default class LoadIndicator extends Vue {
  timer: any
  loading = false
  pollingTimer: any
  polling = false

  created() {
    this.setLoading = this.setLoading.bind(this)
    this.setNotLoading = this.setNotLoading.bind(this)
    EventBus.on(PROGRESS_EVENT_NAMES.START, this.setLoading)
    EventBus.on(PROGRESS_EVENT_NAMES.END, this.setNotLoading)
    EventBus.on(POLLING_NAMES.START, this.startPolling)
    EventBus.on(POLLING_NAMES.END, this.stopPolling)
  }

  destroyed() {
    EventBus.off(PROGRESS_EVENT_NAMES.START, this.setLoading)
    EventBus.off(PROGRESS_EVENT_NAMES.END, this.setNotLoading)
    EventBus.off(POLLING_NAMES.START, this.startPolling)
    EventBus.off(POLLING_NAMES.END, this.stopPolling)
  }

  setLoading() {
    if (!this.loading) {
      clearTimeout(this.timer)
      this.loading = true
    }
  }

  setNotLoading() {
    this.timer = setTimeout(() => {
      this.loading = false
    }, 200)
  }

  public startPolling() {
    if (!this.polling) {
      clearTimeout(this.pollingTimer)
      this.polling = true
    }
  }

  public stopPolling() {
    this.pollingTimer = setTimeout(() => {
      this.polling = false
    }, 200)
  }
}
