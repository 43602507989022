import { Config } from '@/config'
import { AppState } from '@/store/api/AppState'
import { RootState } from '@/store/api/RootState'
import axios from 'axios'
import { ActionContext, ActionTree } from 'vuex'
import { SET_APP_AFFILIATE_ID, SET_APP_NEW_VERSION, SET_APP_VERSION, SET_APP_SHOW_TERMINATION, SET_APP_SHOW_TERMINATION2, SET_APP_DISABLE_PLAN_CHANGE, SET_APP_SHOW_TERM_AND_CONDITION, SET_APP_SUSPEND_REGISTRATION, SET_APP_DEACTIVATE_NEW_DEVICE, SET_APP_DEACTIVATE_UPDATE_PAYMENT, SET_APP_DEACTIVATE_CANCEL_COVERAGE, SET_APP_EXTRA_WAITING_MSG, SET_APP_SERVICE_NOTICE_2, SET_APP_NEW_TERM_USE } from './mutationTypes'

type UserContext = ActionContext<AppState, RootState>

export const actions: ActionTree<AppState, RootState> = {
  async setVersion({ commit }: UserContext, version: string): Promise<void> {
    commit(SET_APP_VERSION, version)
  },
  async checkVersion({ commit, state }: UserContext): Promise<void> {
    const DEFAULT_VERSION = '0'
    const version: string =
    await axios(`${window.location.protocol}//${window.location.host}` + `${Config.publicPath}app-version.json`)
      .then((resp: any) => resp.data.version)
      .catch(() => {
        console.error('error getting version')
        return '0'
      })

    // set initial version
    if (state.version === DEFAULT_VERSION && state.newVersion === DEFAULT_VERSION) {
      commit(SET_APP_VERSION, version)
      commit(SET_APP_NEW_VERSION, version)
      console.log('version: ', version)
    }

    // set if newer version
    if (state.newVersion !== version) {
      commit(SET_APP_NEW_VERSION, version)
      console.log('newer version: ', version)
    }
  },
  async getFeatureFlag({ commit }: UserContext): Promise<void> {
    if (process.env.NODE_ENV === 'production') {
      await axios(`${window.location.protocol}//${window.location.host}` + `${Config.publicPath}client-ids.json`)
        .then((resp: any) => {
          const host = window.location.host.toString()
          const config: any = resp.data[host]
          if (config) {
            const showTermination = config?.showTermination === 'true' || false
            commit(SET_APP_SHOW_TERMINATION, showTermination)

            const showTermination2 = config?.showTermination2 === 'true' || false
            commit(SET_APP_SHOW_TERMINATION2, showTermination2)

            const showTermAndCondition = config?.showTermAndCondition === 'true' || false
            commit(SET_APP_SHOW_TERM_AND_CONDITION, showTermAndCondition)

            const disablePlanChange = config?.disablePlanChange === 'true' || false
            commit(SET_APP_DISABLE_PLAN_CHANGE, disablePlanChange)

            const suspendRegistration = config?.suspendRegistration === 'true' || false
            commit(SET_APP_SUSPEND_REGISTRATION, suspendRegistration)

            const deactivateAddNewDevice = config?.deactivateAddNewDevice === 'true' || false
            commit(SET_APP_DEACTIVATE_NEW_DEVICE, deactivateAddNewDevice)

            const deactivateUpdatePayment = config?.deactivateUpdatePayment === 'true' || false
            commit(SET_APP_DEACTIVATE_UPDATE_PAYMENT, deactivateUpdatePayment)

            const deactivateCancelCoverage = config?.deactivateCancelCoverage === 'true' || false
            commit(SET_APP_DEACTIVATE_CANCEL_COVERAGE, deactivateCancelCoverage)

            const extraWaitingMessage = config?.extraWaitingMessage === 'true' || false
            commit(SET_APP_EXTRA_WAITING_MSG, extraWaitingMessage)

            const servicNotice2 = config?.servicNotice2 === 'true' || false
            commit(SET_APP_SERVICE_NOTICE_2, servicNotice2)

            const newTermUse = config?.newTermUse === 'true' || false
            commit(SET_APP_NEW_TERM_USE, newTermUse)
          } else {
            console.error(`Config not found for: ${host}`)
          }
        })
        .catch((error) => {
          console.error(`error getting config for feature flags: ${error}`)
        })
    } else {
      commit(SET_APP_SHOW_TERMINATION, true)
      commit(SET_APP_SHOW_TERMINATION2, true)
      commit(SET_APP_SHOW_TERM_AND_CONDITION, true)
      commit(SET_APP_DISABLE_PLAN_CHANGE, false)
      commit(SET_APP_SUSPEND_REGISTRATION, true)
      commit(SET_APP_DEACTIVATE_NEW_DEVICE, true)
      commit(SET_APP_DEACTIVATE_UPDATE_PAYMENT, true)
      commit(SET_APP_DEACTIVATE_CANCEL_COVERAGE, true)
      commit(SET_APP_EXTRA_WAITING_MSG, true)
      commit(SET_APP_SERVICE_NOTICE_2, true)
      commit(SET_APP_NEW_TERM_USE, true)
    }
  },
  setAffId({ commit }: UserContext, affId) {
    commit(SET_APP_AFFILIATE_ID, affId)
  }
}
